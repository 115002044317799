import React, {useState} from 'react';
import {downloadPdfReport} from "../../../actions/pdfReports";

const ProcessStatus = (props) => {

    const [showSpinner, setShowSpinner] = useState(false);

    const handleOnDownloadClick = async () => {
        setShowSpinner(true)
        await downloadPdfReport(props.row.id)
        setShowSpinner(false)
    }

    let label = '';

    switch (props.row.status) {
        case 1:
            label = <span className="badge badge-warning">In queue</span>
            break;
        case 2:
            label = <span className="badge badge-info">Processing</span>
            break;
        case 3:
            label = <span onClick={() => handleOnDownloadClick()} className="badge badge-success w-100">{showSpinner === true ? <i className="fas fa-circle-notch fa-spin text-white"></i> : 'Download'}</span>
            break;
        case 10:
            label = <span className="badge badge-danger">Error</span>
            break;
        default:
            return null
    }

    return label
};

export default ProcessStatus