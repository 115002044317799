import {
    SET_STATUS,
    SET_REMOTE_STATUS,
    SET_HOST,
    SET_PROPERTY_TYPE,
    SET_DK_SELLER_RATING,
    SET_FEATURE,
    SET_SORT,
    SET_START_DATE, SET_END_DATE,
    SET_TEXT_SEARCH, SET_NOTES_SEARCH, SET_LABEL, RESET_FILTERS
} from "../actions/reportsListMenu";
import {getStatusFilterOptions} from "../utils/dataSelection/statusFilterOptions";
import {getBookmarkOptions} from "../utils/dataSelection/bookmarkOptions";
import {getReviewedByClientOptions} from "../utils/dataSelection/reviewedByClientOptions";
import getActionRecommendedOptions from "../utils/dataSelection/actionRecommendedOptions";

export const initialState = {
    status: getStatusFilterOptions()[1],
    remoteStatusValue: -1,
    host: '',
    textSearch: '',
    notesSearch: '',
    propertyType: [],
    dkSellerRating: [],
    features: [],
    actionRecommended: getActionRecommendedOptions()[0],
    bookmarked: getBookmarkOptions()[0],
    reviewedByClient: getReviewedByClientOptions()[0],
    startDate: null,
    endDate: null,
    labels: [],
    currentPageValue: 1,
    totalItems: 0,
    itemsCountPerPage: 40,
    sortKey: {value: 'no-sort', label: 'None'},
};

export default function reportsListReducer(state = initialState, action) {
    switch (action.type) {
        case SET_STATUS:
            return {
                ...state,
                status: action.payload.statusValue,
            }
        case SET_REMOTE_STATUS:
            return {
                ...state,
                remoteStatusValue: action.payload.remoteStatusValue
            }
        case SET_HOST:
            return {
                ...state,
                host: action.payload.hostValue
            }
        case SET_TEXT_SEARCH:
            return {
                ...state,
                textSearch: action.payload.value
            }
        case SET_NOTES_SEARCH:
            return {
                ...state,
                notesSearch: action.payload.value
            }
        case SET_PROPERTY_TYPE:
            return {
                ...state,
                propertyType: action.payload.propertyType
            }

        case SET_DK_SELLER_RATING:
            return {
                ...state,
                dkSellerRating: action.payload.dkSellerRating
            }
        case SET_FEATURE:
            return {
                ...state,
                features: action.payload.feature
            }
        // case SET_ACTION_RECOMMENDED:
        //     return {
        //         ...state,
        //         actionRecommended: action.payload.actionRecommended
        //     }
        case SET_SORT:
            return {
                ...state,
                sortKey: action.payload.sortKey
            }
        // case SET_BOOKMARKED_STATUS:
        //     return {
        //         ...state,
        //         bookmarked: action.payload.bookmarkedStatus
        //     }
        // case SET_REVIEWED_BY_CLIENT_STATUS:
        //     return {
        //         ...state,
        //         reviewedByClient: action.payload.reviewedByClientStatus
        //     }
        case SET_START_DATE:
            return {
                ...state,
                startDate: action.payload.startDate,
            }
        case SET_END_DATE:
            return {
                ...state,
                endDate: action.payload.endDate,
            }
        case SET_LABEL:

            let currenLabelValue = action.payload.label

            if (currenLabelValue === null) {
                currenLabelValue = []
            }

            return {
                ...state,
                labels: currenLabelValue
            }
        case RESET_FILTERS:
            return {
                ...action.payload.defaultFilterValues
            }
        default:
            return state
    }
}