import React, { Component } from 'react';

class AcceptAndManage extends Component {

    render() {
        return (
            <>
                <button onClick={() => this.props.handleManageCookies()} className="btn btn-outline-primary mr-3 ml-3">Manage Cookies</button>
                <button onClick={() => this.props.handleRejectCookies()} className="btn btn-outline-primary mr-3">Reject optional cookies</button>
                <button onClick={() => this.props.handleAcceptCookies()} className="btn btn-primary">Accept optional cookies</button>
            </>
        )
    }
}

export default AcceptAndManage