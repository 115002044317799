import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, DropdownItem, DropdownMenu} from "reactstrap";
import ProcessStatus from "../pdfReports/ProcessStatus";
import {doCreatePdfReport, fetchPdfReportsList} from "../../../actions/pdfReports";
import moment from "moment";
import {format} from "date-fns";

const DropdownMenuAction = props => {

    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {

        props.fetchPdfReportsList()
        const pdfPollingInterval = setInterval(() => props.fetchPdfReportsList(), 4000)

        return () => {
            clearInterval(pdfPollingInterval)
        }
    }, [])

    const handleAction = async (e) => {
        setShowSpinner(true)
        await props.doCreatePdfReport(props.row.title, props.row.id)
        setShowSpinner(false)
    }

    return (
        <DropdownMenu className="pdf-reports-dropdown-menu">
            <DropdownItem toggle={false}>
            <Button
                onClick={(e) => handleAction(e)}
                color="primary"
                size="sm"
            >
                {showSpinner ? <i className="fas fa-circle-notch fa-spin text-primary"></i> : 'Generate report'}
            </Button>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>Previous reports</DropdownItem>
            {props.pdfReports.list.filter(pdfReport => props.row.id === pdfReport.dupe_list.id).sort((a,b) => new Date(b.created) - new Date(a.created)).map(pdfReport => {
                return (
                    <DropdownItem className="justify-content-between" toggle={false} key={pdfReport.id}>
                        <span>{props.row.title}</span><span>{format(new Date(pdfReport.created), 'dd/MM/yyyy')}</span><ProcessStatus row={pdfReport} />
                    </DropdownItem>
                )
            })}
        </DropdownMenu>
    )
}

const mapStateToProps = state => {
    return {
        pdfReports: state.pdfReports
    }
}

const mapActionsToProps = dispatch => {
    return {
        fetchPdfReportsList: () => {
            dispatch(fetchPdfReportsList())
        },
        doCreatePdfReport: (reportName, listId) => {
            dispatch(doCreatePdfReport(reportName, listId))
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DropdownMenuAction);

