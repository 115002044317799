export const ROUTES = {
        projectList: {name: 'projectList', path:'/', displayName: 'Project List'},
        login: {name: 'login', path: '/login', displayName: 'Login'},
        dashboard: {name: 'dashboard', path: '/dashboard', displayName: 'Dashboard'},
        reportList: {name: 'reportList', path: '/singular-view', displayName: 'Singular View'},
        galleryList: {name: 'galleryList', path: '/gallery', displayName: 'Gallery View'},
        userLists: {name: 'userLists',  path: '/lists', displayName: 'User Lists'},
        userList: {name: 'userList', path: '/list/:id', displayName: 'User Lists'},
        pdfReports: {name: 'pdfReports', path: '/export/pdf', displayName: 'PDF Reports'},
        tags: {name: 'tags', path: '/tags', displayName: 'Tags'},
        manageCookies: {name: 'manageCookies', path: '/manage-cookies', displayName: 'Manage Cookies'}
}
