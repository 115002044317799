export const SET_FILTER_PANEL_TOGGLE = 'gallerySubheader:setFilterPanelToggle';
export const SET_ADD_TO_LIST_MODAL_TOGGLE = 'gallerySubheader:setAddToListModalToggle';

export const setFilterPanelToggle = (status) => {
    return {
        type: SET_FILTER_PANEL_TOGGLE,
        payload: {
            status
        }
    }
}

export const setAddToListModalToggle = status => {
    return {
        type: SET_ADD_TO_LIST_MODAL_TOGGLE,
        payload: {
            status
        }
    }
}
