import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthAgent from '../../libs/AuthAgent';
import jwt_decode from 'jwt-decode';
import Cookies from "js-cookie";
import {ROUTES} from "../../routes/frontend";

const ProtectedRoute = ({ component: Component, ...rest }) => {

    // if (!AuthAgent.tokeCookieExists()) {
    //     return <Redirect to={ROUTES.login.path}/>
    // }
    //
    // try {
    //     let decodedToken = jwt_decode(Cookies.get('token'))
    //
    //     if (Date.now()  >= decodedToken.exp * 1000) {
    //         console.log("Token expired. Redirecting to login.")
    //         return <Redirect to={ROUTES.login.path}/>
    //     }
    // } catch (e) {
    //     return <Redirect to={ROUTES.login.path}/>
    // }

    let rests = {...rest}

    return (
      <Route {...rest} render={props => <Component pageTitle={ROUTES[rests.name].displayName} {...props} {...rest} />}/>
    )
}

export default ProtectedRoute;
