export function handleDkSellerRatingDisplayLabel(dkSellerRating) {
    switch (parseInt(dkSellerRating)) {
        case 1:
            return 'Low volume'
        case 2:
            return 'Medium volume'
        case 3:
            return 'High volume'
        default:
            return 'Unknown'
    }
}

export function handleActionRecommendedDisplayLabel(actionRecommended) {
    switch (parseInt(actionRecommended)) {
        case 0:
            return 'No';
        case 1:
            return 'Yes';
        default:
            return 'Not established'
    }
}

export function handlePropertyTypeDisplayLabel(propertyType) {
    switch (parseInt(propertyType)) {
        case 1:
            return 'Marketplace';
        case 2:
            return 'Social';
        case 3:
            return 'Website';
        default:
            return 'Unknown'
    }
}

export function handleTestPurchaseDisplayLabel(testPurchase) {
    switch (parseInt(testPurchase)) {
        case 0:
            return 'No';
        case 1:
            return 'Yes';
        default:
            return 'Not established'
    }
}

export function handleEnforcementStatusDisplayLabel(enforcementStatus) {
    switch (parseInt(enforcementStatus)) {
        case 1:
            return 'No action';
        case 2:
            return 'Identified';
        case 3:
            return 'Awaiting instructions';
        case 4:
            return 'No further action';
        default:
            return 'Not established'
    }
}

export function handleTakedownStatusDisplayLabel(enforcementStatus) {
    switch (parseInt(enforcementStatus)) {
        case 1:
            return 'Not required';
        case 2:
            return 'Not sent';
        case 3:
            return 'Sent';
        case 4:
            return 'Completed';
        case 5:
            return 'Queried review';
        default:
            return 'Not established'
    }
}

export function handleDataMappings(dataKey, dataValue) {
    switch (dataKey) {
        case 'dk_seller_rating':
            return handleDkSellerRatingDisplayLabel(dataValue)
        case 'action_recomended':
            return handleActionRecommendedDisplayLabel(dataValue)
        case 'property_type':
            return handlePropertyTypeDisplayLabel(dataValue)
        case 'test_purchase':
            return handleTestPurchaseDisplayLabel(dataValue)
        case 'enforcement_status':
            return handleEnforcementStatusDisplayLabel(dataValue)
        case 'estimated_monthly_visits':
            if (dataValue !== null) {
                return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(dataValue)
            }

            return 'Unknown'

        case 'dupe_value':
            return dataValue === null ? 'N/A' : dataValue.toFixed(2) + ' %';
        case 'model_score_report':
            return dataValue === null ? 'N/A' : dataValue.toFixed(2) + ' %';
        case 'takedown_status':
            return handleTakedownStatusDisplayLabel(dataValue)
        default:
            return dataValue
    }
}

