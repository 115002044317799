import React, {Component} from 'react';
import {ToastContainer} from 'react-toastify';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ProtectedRoute from './authentication/ProtectedRoute';
import Login from './pages/Login';
import ProjectList from './pages/ProjectList';
import Summary from './pages/Summary';
import ReportsList from "./pages/ReportsList";
import Lists from "./pages/Lists";
import ListView from "./pages/ListView";
import PdfReports from "./pages/PdfReports";
import GalleryList from "./pages/GalleryList";
import Labels from "./pages/Labels";
import CookieConsent, {Cookies}  from "react-cookie-consent";
import ManageCookies from "./pages/ManageCookies";
import AuthAgent from "../libs/AuthAgent";
import {ROUTES} from "../routes/frontend";
import {connect} from 'react-redux';
import AcceptAndManage from "./parts/cookie/AcceptAndManage";
import {COOKIE_DURATION, COOKIE_NAME} from "../utils/cookies/cookieConstants";
import axios from "axios";

// import {appInsights, reactPlugin} from "../utils/appInsights/getInstance";
// import { withAITracking } from '@microsoft/applicationinsights-react-js';




class ContainedRoutes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showing: true
        }
    }

    handleManageCookies = () => {
        this.props.history.push(ROUTES.manageCookies.path)
    }

    handleAcceptCookies = (acceptedOnScroll) => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + '/cookies/accept',
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders()
        }).then(() => {
            Cookies.set(COOKIE_NAME, true, {expires: COOKIE_DURATION, domain: process.env.HOST})
            this.setState({
                showing: false
            })
            window.location.reload()
        }).catch(e => {
            console.log(e)
        });
    }

    handleRejectCookies = () => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + '/cookies/reject',
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders()
        }).then(() => {
            Cookies.set(COOKIE_NAME, false, {expires: COOKIE_DURATION, domain: process.env.HOST})
            this.setState({
                showing: false
            })
            window.location.reload()
        }).catch(e => {
            console.log(e)
        })
    }

    render() {
        return (
            <Route>
                {this.state.showing &&
                <CookieConsent
                    location="bottom"
                    buttonText="Accept optional cookies"
                    cookieName="cookie-consent"
                    // buttonStyle={{ color: "#4e503b" }}
                    buttonClasses={"btn btn-primary mb-2"}
                    declineButtonClasses={"btn btn-outline-secondary mb-2 mr-2 ml-3"}
                    style={{
                        display: 'block',
                        background: '#000',
                        borderTop: '1px solid #86bc25',
                        paddingTop: '40px',
                        paddingBottom: '40px'
                    }}
                    expires={COOKIE_DURATION}
                    // enableDeclineButton
                    // declineButtonText={"Reject optional cookies"}
                    // debug={true}
                    acceptOnScrol={false}
                    onAccept={(acceptedByScrolling) => this.handleAcceptCookies(acceptedByScrolling)}
                    onDecline={() => this.handleRejectCookies()}
                    disableButtonStyles
                    ButtonComponent={() => <AcceptAndManage
                        handleManageCookies={this.handleManageCookies}
                        handleAcceptCookies={this.handleAcceptCookies}
                        handleRejectCookies={this.handleRejectCookies}
                    />}
                >
                    <h4 className="mb-3">Your privacy settings</h4>
                    <h5 className="mb-3">Choose your cookie preferences before you continue</h5>
                    <p className="mb-3 text-gray-100">Deloitte uses strictly necessary cookies and similar technologies to operate this website. In
                        addition to strictly necessary cookies, Deloitte uses optional cookies to enhance and
                        personalise your experience, conduct analytics such as count visits and traffic sources, and
                        interact with third parties. Press, 'Accept optional cookies' if you agree to the use of
                        optional cookies. For more information or to amend your preferences at any time, please
                        click, 'Manage cookies'.</p>
                    {/*<button onClick={() => this.handleManageCookies()} className="btn btn-outline-primary">Manage cookies*/}
                    {/*</button>*/}
                </CookieConsent>
                }
                <Switch>
                    <ProtectedRoute name={ROUTES.manageCookies.name} path={ROUTES.manageCookies.path}  exact component={ManageCookies} />
                    <ProtectedRoute name={ROUTES.projectList.name} path={ROUTES.projectList.path} exact component={ProjectList}/>
                    <ProtectedRoute name={ROUTES.dashboard.name} path={ROUTES.dashboard.path} exact component={Summary}/>
                    <ProtectedRoute name={ROUTES.reportList.name} path={ROUTES.reportList.path} exact component={ReportsList}/>
                    <ProtectedRoute name={ROUTES.galleryList.name} path={ROUTES.galleryList.path} exact component={GalleryList}/>
                    <ProtectedRoute name={ROUTES.userLists.name} path={ROUTES.userLists.path} exact component={Lists}/>
                    <ProtectedRoute name={ROUTES.userList.name} path={ROUTES.userList.path} exact component={ListView}/>
                    {/*<ProtectedRoute name={ROUTES.pdfReports.name} path={ROUTES.pdfReports.path} exact component={PdfReports}/>*/}
                    <ProtectedRoute name={ROUTES.tags.name} path={ROUTES.tags.path} exact component={Labels}/>
                </Switch>
            </Route>
        )
    }
}

class App extends Component {

    constructor(props) {
        super(props);

        // if (Cookies.get(COOKIE_NAME) === 'true' && typeof appInsights.context === "undefined") {
        //     console.log('loading...')
        //     appInsights.loadAppInsights()
        // }
    }

    render() {

        return (
            <>
              <Router>
                  <Switch>
                      <Route name={ROUTES.login.name} path={ROUTES.login.path} exact render={(routeProps => (
                        <Login {...routeProps} />
                      ))}/>
                      <Route render={routeProps => (
                          <ContainedRoutes {...routeProps} auth={this.props.authentication} />
                      )}/>
                      <Route label="No match"/>
                  </Switch>
                  <ToastContainer
                    autoClose={2000}
                    position="bottom-center"
                    hideProgressBar={false}
                  />
              </Router>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
    }
};

const mapActionsToProps = (dispatch, ownProps) => {
    return {
    }
};

// export default connect(mapStateToProps, mapActionsToProps)(withAITracking(reactPlugin, App));
export default connect(mapStateToProps, mapActionsToProps)(App);