import * as gallerySubheaderActions from '../actions/gallerySubheader';

export const initialState = {
    filtersTogglePanel: false,
    addToListModalToggle: false
};

export default function gallerySubheader(state = initialState, action) {
    switch (action.type) {

        case gallerySubheaderActions.SET_FILTER_PANEL_TOGGLE:
            return {
                ...state,
                filtersTogglePanel: action.payload.status
            }

        case gallerySubheaderActions.SET_ADD_TO_LIST_MODAL_TOGGLE:
            return {
                ...state,
                addToListModalToggle: action.payload.status
            }
        default:
            return state
    }
}
