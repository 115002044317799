import React, {Component} from "react";
import {RadialChart} from "react-vis";
import {formatNumberAsIntl} from "../../../../utils/various/format";
import {initialState} from "../../../../reducers/reportsListMenuReducer";
import getPropertyTypeFilterOptions from "../../../../utils/dataSelection/propertyTypeFilterOptions";
import {ROUTES} from "../../../../routes/frontend";

class PropertyTypeSplit extends Component {

    parseColor = (value, maxVal, minVal) => {
        if (value === maxVal) {
            return "#496812"
        }
        if (value === minVal) {
            return "#DCF2A1"
        }
        return "#86bc25"
    }

    prepDataForChart = () => {

        let propTypeDistributionArray = [
            {"key": "web", "count": this.props.propertyTypeDistribution['web']},
            {"key": "marketplace", "count": this.props.propertyTypeDistribution['marketplace']},
            {"key": "social", "count": this.props.propertyTypeDistribution['social']}
        ]

        let maxCount = Math.max(...propTypeDistributionArray.map(i => i.count))
        let minCount = Math.min(...propTypeDistributionArray.map(i => i.count))



        let data = [];

        for (const prop in this.props.propertyTypeDistribution) {
            if (this.props.propertyTypeDistribution[prop] > 0 ) {
                // data.push({angle: this.props.propertyTypeDistribution[prop], name: prop, color: this.generateColorWithAlpha(maxCount, this.props.propertyTypeDistribution[prop], [78, 114, 223]), initialValue: this.props.propertyTypeDistribution[prop]})
                data.push({angle: this.props.propertyTypeDistribution[prop], name: prop, color: this.parseColor(this.props.propertyTypeDistribution[prop], maxCount, minCount), initialValue: this.props.propertyTypeDistribution[prop]})
            }
        }

        return data;
    }

    getLabel = (d) => {
        return `${d.name} (${formatNumberAsIntl(d.initialValue)})`
    }

    // getHighestValuePropertyType = () => {
    //     return Object.keys(this.props.propertyTypeDistribution).reduce((a, b,c) => this.props.propertyTypeDistribution[a] > this.props.propertyTypeDistribution[b] ? a : b);
    // }

    onPropertyTypeValueSplit = (value) => {

        this.props.resetFilters(initialState)

        const { name } = value

        let option = []

        switch (name) {
            case 'web':
                option = getPropertyTypeFilterOptions().find(option => option.label.toLowerCase() === name)
                this.props.setStartDate(new Date(this.props.summary.from))
                this.props.setEndDate(new Date(this.props.summary.to))
                this.props.setPropertyType([option])
                this.props.router.push(ROUTES.galleryList.path)
                break;
            case 'social':
                option = getPropertyTypeFilterOptions().find(option => option.label.toLowerCase() === name)
                this.props.setStartDate(new Date(this.props.summary.from))
                this.props.setEndDate(new Date(this.props.summary.to))
                this.props.setPropertyType([option])
                this.props.router.push(ROUTES.galleryList.path)
                break;
            case 'marketplace':
                option = getPropertyTypeFilterOptions().find(option => option.label.toLowerCase() === name)
                this.props.setStartDate(new Date(this.props.summary.from))
                this.props.setEndDate(new Date(this.props.summary.to))
                this.props.setPropertyType([option])
                this.props.router.push(ROUTES.galleryList.path)
                break;
            default:
                this.props.setPropertyType(option) // Default value
        }
    }

    render() {

        return (
          <div className="card property-type-split">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold">Dupes split by property type</h6>
              </div>
              <div className="card-body">
                  <div className="row">
                      {/*<div className="col-12 pie-split-text">*/}
                      {/*    <h4>WE SCAN WEB, MARKETPLACES & SOCIAL</h4>*/}
                      {/*    Of the {formatNumberAsIntl(this.props.dupeCount)} dupes that we found, most were found on {this.getHighestValuePropertyType() === 'web' ? 'website' : this.getHighestValuePropertyType()}*/}
                      {/*    <hr />*/}
                      {/*</div>*/}
                      <div className="col-8 offset-2 d-flex chart justify-content-center align-items-center pie-split">
                          <RadialChart
                            className="radial-chart-property-type-split"
                            colorType={'literal'}
                            colorDomain={[0, 100]}
                            colorRange={[0, 10]}
                            margin={{top: 100}}
                            getLabel={d => this.getLabel(d)}
                            data={this.prepDataForChart()}
                            labelsRadiusMultiplier={1.4}
                            labelsStyle={{fontSize: 14, fill: 'black', fontWeight: 'bold', textTransform: 'capitalize'}}
                            showLabels
                            style={{stroke: '#fff', strokeWidth: 0.5}}
                            width={480}
                            height={480}
                            radius={170}
                            onValueClick={value => this.onPropertyTypeValueSplit(value)}
                            onValueMouseOver={val => console.log(val)}
                          />
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

export default PropertyTypeSplit;