import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });
let reactPlugin = new ReactPlugin();

let appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        disableTelemetry: false,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        }
    }
});

export {appInsights, reactPlugin}
