import Cookies from 'js-cookie';

class AuthAgent {

    tokeCookieExists() {

        if (typeof Cookies.get('token') !== "undefined") {
            return true
        }

        return false
    }

    setCookie(name, value, options = {}) {
        Cookies.set(name, value, options)
    }

    removeLoginCookie() {
        Cookies.remove('token')
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json'
        }
    }
}

export default new AuthAgent()