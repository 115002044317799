import AuthAgent from '../libs/AuthAgent';
import * as paginationActions from './pagination';
import axios from "axios";

export const FETCHING = "gallery:fetching";
export const SET_IMAGES = "gallery:setImages";
export const SET_IMAGE_FOR_SIDEPANEL = "gallery;setImageForSidepanel";
export const SIDEPANEL_STATUS = "gallery:SidepanelStatus";

export const fetchingImages = status => {
    return {
        type: FETCHING,
        payload: {
            status
        }
    }
}

export const setImages = images => {
    return {
        type: SET_IMAGES,
        payload: {
            images
        }
    }
}

// Gallery Side panel
export const setImageForSidepanel = imageData => {
    return {
        type: SET_IMAGE_FOR_SIDEPANEL,
        payload: {
            imageData
        }
    }
}

export const isSidePanelOpen = status => {
    return {
        type: SIDEPANEL_STATUS,
        payload: {
            status
        }
    }
}

export function fetchGallerySearchResults(filters, selectedImages = []) {
    return dispatch => {
        dispatch(fetchingImages(true));

        let payload = {
            project: JSON.parse(localStorage.getItem('project')).id,
            filters,
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search-results/gallery",
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            dispatch(fetchingImages(false));
            dispatch(paginationActions.setTotalItems(response.data.total));
            dispatch(setImages(response.data.search_results));
        }).catch(error => {
            dispatch(fetchingImages(false));
            console.log(error)
        })
    }
}

export function doOpenAndSetImageForGallery(selectedImage) {
    return dispatch => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/${selectedImage.id}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setImageForSidepanel(response.data))
            dispatch(isSidePanelOpen(true))
        }).catch(error => {
            console.log(error.message)
        })
    }
}

export const doCloseAndUnsetImageForGallery = () => {
    return dispatch => {
        dispatch(isSidePanelOpen(false))
        dispatch(setImageForSidepanel(null))
    }
}
