import React, { Component } from 'react';
import {capitalizeFirstLetterOfWord} from "../../../utils/various/stringManipulation";


class ProjectListItem extends Component {

    render() {
        return (
              <div className="col-3" onClick={(e) => this.props.setSelectedProject(this.props.project.id)}>
                  <div className="card project-card mb-3">
                      <div style={{backgroundImage: "url(" + this.props.project.view_template.styles.image_url + ")", backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} className="project-item-image-container"></div>
                      <div className="card-body text-center">
                          <p className="m-0">{capitalizeFirstLetterOfWord(this.props.project.display_name)}</p>
                      </div>
                  </div>
              </div>
        )
    }
}

export default ProjectListItem