import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import DeloitteLogo from './../../../assets/images/del_logo_white.png';
import { withRouter } from "react-router-dom";

class Sidebar extends Component {

    render() {
        return (
          <ul className="navbar-nav bg-black sidebar accordion" id="accordionSidebar">
              <a href="/" className="sidebar-brand d-flex align-items-center">
                  <div className="sidebar-brand-icon">
                      <img className="img-fluid logo" src={DeloitteLogo} alt="Deloitte Logo" />
                  </div>
              </a>

              <hr className="sidebar-divider"/>

              <li  className={`nav-item`}>
                  <NavLink activeClassName={"active"} to={`/dashboard`} className="nav-link">
                      <span>Dashboard</span>
                  </NavLink>
              </li>

              <li  className={`nav-item`}>
                  <NavLink activeClassName={"active"} to={`/singular-view`}  className="nav-link">
                      <span>Singular View</span>
                  </NavLink>
              </li>

              <li  className={`nav-item`}>
                  <NavLink activeClassName={"active"} to={`/gallery`} className="nav-link">
                      <span>Gallery View</span>
                  </NavLink>
              </li>

              <li  className={`nav-item`}>
                  <NavLink activeClassName={"active"} to={`/lists`} className="nav-link">
                      <span>Lists & Reports</span>
                  </NavLink>
              </li>

              {/*<li  className={`nav-item`}>*/}
              {/*    <NavLink activeClassName={"active"} to={`/export/pdf`} className="nav-link">*/}
              {/*        <span>PDF Reports</span>*/}
              {/*    </NavLink>*/}
              {/*</li>*/}

              <li  className={`nav-item`}>
                  <NavLink activeClassName={"active"} to={`/tags`} className="nav-link">
                      <span>Tags</span>
                  </NavLink>
              </li>
          </ul>
        )
    }
}

export default withRouter(Sidebar);
