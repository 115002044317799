import * as summaryActions from "../actions/summary";

const initialState = {
    summaryList: [],
    summary: null,
    selectedSummaryOption: null,
    fetching: true
};

export default function summaryReducer(state = initialState, action) {
    switch (action.type) {
        case summaryActions.SET_FETCHING:
            return {
                ...state,
                fetching: action.payload.status
            }
        case summaryActions.SET_SUMMARY_LIST:
            return {
                ...state,
                summaryList: action.payload.list
            }
        case summaryActions.SET_SUMMARY:
            return {
                ...state,
                summary: action.payload.summary
            }
        case summaryActions.SET_SUMMARY_OPTION:
            return {
                ...state,
                selectedSummaryOption: action.payload.option
            }
        default:
            return state
    }
}
