import React, {Component} from 'react';
import BarChart from "./summaryComponents/BarChart"
import ExecutiveSummary from "./summaryComponents/ExecutiveSummary";
import WordCloud from "./summaryComponents/WordCloud";
import TopWebsitesFeaturingDupesByDupesCount from "./summaryComponents/TopWebsitesFeaturingDupesByDupesCount";
import SignificantGeographies from "./summaryComponents/SignificantGeographies";
import PropertyTypeSplit from "./summaryComponents/PropertyTypeSplit";
import TopWebsitesFeaturingDupesByEmv from "./summaryComponents/TopWebsitesFeaturingDupesByEmv";
import {Link, withRouter} from "react-router-dom";
import {ROUTES} from "../../../routes/frontend";
import {
    resetFilters,
    setEndDate,
    setHost, setPropertyType,
    setRemoteStatus,
    setStartDate,
    // setStatus
} from "../../../actions/reportsListMenu";
import {compose} from "redux";
import {connect} from "react-redux";

class SummaryView extends Component {

    render() {

        return (
            <>
                <div className="col-12 mb-4">
                    <ExecutiveSummary
                        setRemoteStatus={this.props.setRemoteStatus}
                        setHost={this.props.setHost}
                        setStartDate={this.props.setStartDate}
                        setEndDate={this.props.setEndDate}
                        router={this.props.history}
                        dupeCount={this.props.summary.summary_data.dupe_count}
                        scannedPages={this.props.summary.summary_data.pages_scanned}
                        actionRecommendedPercent={this.props.summary.summary_data.enforcement_recommended_percentage}
                        highestVolumeProperty={this.props.summary.summary_data.highest_traffic_website_domains}
                        summary={this.props.summary}
                        topWebsitesByCount={this.props.summary.summary_data.highest_dupe_count_website_domains}
                        resetFilters={this.props.resetFilters}
                    />
                </div>
                <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-4">
                    <TopWebsitesFeaturingDupesByDupesCount
                        data={this.props.summary.summary_data.highest_dupe_count_website_domains}
                        setRemoteStatus={this.props.setRemoteStatus}
                        setHost={this.props.setHost}
                        setStartDate={this.props.setStartDate}
                        setEndDate={this.props.setEndDate}
                        router={this.props.history}
                        summary={this.props.summary}
                        resetFilters={this.props.resetFilters}
                    />
                </div>
                <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-4">
                    <TopWebsitesFeaturingDupesByEmv
                        data={this.props.summary.summary_data.highest_traffic_website_domains}
                        setHost={this.props.setHost}
                        setStartDate={this.props.setStartDate}
                        setEndDate={this.props.setEndDate}
                        router={this.props.history}
                        summary={this.props.summary}
                    />
                </div>
                <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-4">
                    <PropertyTypeSplit
                        summary={this.props.summary}
                        dupeCount={this.props.summary.summary_data.dupe_count}
                        propertyTypeDistribution={this.props.summary.summary_data.property_type_split}
                        router={this.props.history}
                        resetFilters={this.props.resetFilters}
                        setPropertyType={this.props.setPropertyType}
                        setStartDate={this.props.setStartDate}
                        setEndDate={this.props.setEndDate}
                    />
                </div>
                <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-4">
                    <BarChart
                        summary={this.props.summary}
                        data={this.props.summary.summary_data.platform_split}
                        dupeCount={this.props.summary.summary_data.dupe_count}
                        scannedPages={this.props.summary.summary_data.pages_scanned}
                        showWeb={true}
                        // title={'WEB, MARKETPLACE & SOCIAL BREAKDOWN'}
                        // subTitle={`We scanned ${formatNumberAsIntl(this.props.summary.summary_data.pages_scanned)} pages. In total ${formatNumberAsIntl(this.props.summary.summary_data.dupe_count)} dupes were discovered, split across the following platforms.`}
                        cardTitle="Dupes split by platform"
                        resetFilters={this.props.resetFilters}
                        setPropertyType={this.props.setPropertyType}
                        router={this.props.history}
                        setStartDate={this.props.setStartDate}
                        setEndDate={this.props.setEndDate}
                        setRemoteStatus={this.props.setRemoteStatus}
                        setHost={this.props.setHost}

                    />
                </div>
                {/*<div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-3">*/}
                {/*    <BarChart*/}
                {/*      data={this.props.summary.summary_data.platform_split}*/}
                {/*      dupeCount={this.props.summary.summary_data.dupe_count}*/}
                {/*      scannedPages={this.props.summary.summary_data.pages_scanned}*/}
                {/*      showWeb={false}*/}
                {/*      title={'MARKETPLACE & SOCIAL BREAKDOWN'}*/}
                {/*      // In subtitle we get the dupe count without web. So we filter the platform_split to get web then we subtract the count of web from total dupe count*/}
                {/*      subTitle={`We scanned ${formatNumberAsIntl(this.props.summary.summary_data.pages_scanned)} pages. In total ${formatNumberAsIntl(this.props.summary.summary_data.dupe_count - this.props.summary.summary_data.platform_split.filter(item => item.platform === 'Web')[0].count)} dupes were discovered on marketplace and social platforms.`}*/}
                {/*      cardTitle="Dupes split by platform excluding web"*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-3">*/}
                {/*    <BarChart*/}
                {/*      data={this.props.summary.summary_data.seller_significance}*/}
                {/*      dupeCount={this.props.summary.summary_data.dupe_count}*/}
                {/*      scannedPages={this.props.summary.summary_data.pages_scanned}*/}
                {/*      showWeb={false}*/}
                {/*      title={'WE ASSESS THE SIGNIFICANCE OF EACH SELLER'}*/}
                {/*      subTitle={`Based on monthly site visits (for web), social followers, or marketplace reputation and visibility, we assess the significance of each seller`}*/}
                {/*      cardTitle="Seller significance"*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-12 mb-4">
                    <SignificantGeographies
                        data={this.props.summary.summary_data.significant_geographies}
                        dupeCount={this.props.summary.summary_data.dupe_count}
                    />
                </div>
                <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12">
                    <WordCloud img={this.props.summary.summary_data.wordcloud}/>
                </div>
                <div className="col-sm-12 text-center p-5 mb-4">
                    <Link to={ROUTES.galleryList.path}>
                        <button className="btn btn-secondary mr-3">{ROUTES.galleryList.displayName}</button>
                    </Link>
                    <Link to={ROUTES.reportList.path}>
                        <button className="btn btn-secondary">{ROUTES.reportList.displayName}</button>
                    </Link>
                </div>
            </>

        );
    }
}

const mapStateToProps = () => {
    return {
    }
}
const mapActionsToProps = (dispatch) => {
    return {
        setRemoteStatus: (status) => {return dispatch(setRemoteStatus(status))},
        setHost: (host) => {return dispatch(setHost(host))},
        setStartDate: (startDate) => {return dispatch(setStartDate(startDate))},
        setEndDate: (endDate) => {return dispatch(setEndDate(endDate))},
        setPropertyType: (propertyType) => dispatch(setPropertyType(propertyType)),
        resetFilters: (initialValues) => {return dispatch(resetFilters(initialValues))}
    }
}

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(SummaryView);
