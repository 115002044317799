import React, {Component} from 'react';
import {connect} from 'react-redux';
import Topbar from "../parts/layout/Topbar";
import ProjectListItem from "../parts/project/ProjectListItem"
import {fetchProjectList} from "../../actions/projects";
import {ROUTES} from "../../routes/frontend";

class ProjectList extends Component {

    constructor(props) {
        super(props);

        // let userId = `${localStorage.getItem('user_handle')}-${process.env.REACT_APP_ENV}`;
        //
        // window.pendo.initialize({
        //     visitor: {
        //         id:              userId  // Required if user is logged in, default creates anonymous ID
        //         // email:        // Recommended if using Pendo Feedback, or NPS Email
        //         // full_name:    // Recommended if using Pendo Feedback
        //         // role:         // Optional
        //
        //         // You can add any additional visitor level key-values here,
        //         // as long as it's not one of the above reserved names.
        //     },
        //     account: {
        //         id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        //         // name:         // Optional
        //         // is_paying:    // Recommended if using Pendo Feedback
        //         // monthly_value:// Recommended if using Pendo Feedback
        //         // planLevel:    // Optional
        //         // planPrice:    // Optional
        //         // creationDate: // Optional
        //
        //         // You can add any additional account level key-values here,
        //         // as long as it's not one of the above reserved names.
        //     }
        // });
    }

    componentDidMount() {
        this.props.fetchProjectList()
    }

    setSelectedProject = (projectId) => {
        let project = this.props.projects.projectList.find(project => {
            return project.id === projectId ? project : null
        })

        localStorage.setItem('project', JSON.stringify(project))
        this.props.history.push(ROUTES.dashboard.path)
    }

    render() {
        return (
          <>
              <Topbar/>
              <div className="container no-subheader">
                  <div className="row">
                      <div className="col-12">
                          <h3 className="text-center">Active designs</h3>
                          <hr/>
                      </div>
                      {this.props.projects.fetching ? (
                        <div className="col-12 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        </div>
                      ) : (
                        this.props.projects.projectList.map(project => {
                            return <ProjectListItem
                              setSelectedProject={this.setSelectedProject}
                              key={project.id}
                              project={project}
                            />
                        })
                      )}

                  </div>
              </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.projects
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        fetchProjectList: () => {
            dispatch(fetchProjectList())
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectList);