const prepareFilters = (filters) => {

    let status = filters.status.value

    if (filters.remoteStatusValue !== -1) {
        status = filters.remoteStatusValue.value
    }

    return {
        status: status,
        host: filters.host,
        textSearch: filters.textSearch,
        notesSearch: filters.notesSearch,
        propertyType: filters.propertyType.map(property => property.value) || [],
        dkSellerRating: filters.dkSellerRating.map(dkSellerRating => dkSellerRating.value) || [],
        features: filters.features,
        // actionRecommended: filters.actionRecommended.value || '',
        // bookmarked: filters.bookmarked.value,
        // reviewedByClient: filters.reviewedByClient.value,
        startDate: filters.startDate,
        endDate: filters.endDate,
        labels: filters.labels.map(label => label.value),
        sortKey: filters.sortKey.value,
    }
}

export default prepareFilters;
