import AuthAgent from '../libs/AuthAgent';
import {toast} from "react-toastify";
import download from 'downloadjs';
import moment from "moment";
import axios from "axios";

export const SET_FETCHING = "pdfReports:setFetching";
export const SET_PDF_REPORTS_LIST = 'pdfReports:setList';
export const ADD_PDF_REPORT = 'pdfReports:addReport';

const setFetchingStatus = (status) => {
    return {
        type: SET_FETCHING,
        payload: {
            status
        }
    }
}

const setPdfReportsList = (listData) => {
    return {
        type: SET_PDF_REPORTS_LIST,
        payload: {
            listData
        }
    }
}

const addPdfReport = (reportData) => {
    return {
        type: ADD_PDF_REPORT,
        payload: {
            reportData
        }
    }
}

export const fetchPdfReportsList = () => {
    return dispatch => {
        dispatch(setFetchingStatus(true))

        let projectId = JSON.parse(localStorage.getItem('project')).id

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/pdf-reports/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setPdfReportsList(response.data))
            dispatch(setFetchingStatus(false))
        }).catch(error => {
            dispatch(setFetchingStatus(false))
            console.log(error.message)
            toast('Something went wrong')
        })
    }
}

export const doCreatePdfReport = (reportName, listId) => {
    return async dispatch => {

        let payload = {
            list_id: listId,
            name: reportName,
        }

        try {
            let response = await axios({
                url: process.env.REACT_APP_API_BASE_URL + `/pdf-report/create`,
                method: 'POST',
                withCredentials: true,
                headers: AuthAgent.getHeaders(),
                data: payload
            });
            dispatch(addPdfReport(response.data))
        } catch (e) {
            toast('Something went wrong')
            console.log(e)
            // return {type: 'Failed to add', payload: {}}
        }
    }
}

export const downloadPdfReport = async pdfReportId => {
    // return async dispatch => {

        try {
            let response = await axios({
                url: process.env.REACT_APP_API_BASE_URL + `/pdf-report/download/${pdfReportId}`,
                method: 'GET',
                withCredentials: true,
                headers: AuthAgent.getHeaders(),
                responseType: 'blob'
            })

            let fileName = `pdf_report_${moment().format('YYYY-MM-DD_H:m')}.pdf`;
            download(response.data, fileName, "text/plain");
        } catch (e) {
            console.log(e)
            toast('Something went wrong')
        }

    // }
}
