import React from 'react';
import Sticky from 'react-sticky-el';
import Select from "react-select";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: (state.isFocused || state.isHover) && '1px solid #86bc25',
        boxShadow: state.isFocused && '0 0 0 0.2rem #86bc25',
        borderRadius: 0,
        '&:hover': {
            borderColor: '1px solid #86bc25'
        },
    }),
    dropdownIndicator: (provided, { data }) => ({
        ...provided,
        color: '#000'
    }),
    indicatorSeparator: (provided, { data }) => ({
        ...provided,
        display: 'none'
    }),
    singleValue: (provided, { data }) => ({
        ...provided,
        fontSize: '14px',
        color: '#53565A'
    }),
}

class DashboardSub extends React.Component {

    render() {
        return (
            <div className="sticky-container">
                <Sticky stickyClassName={'sticky'}>
                    <div className="subheader summary-subheader">
                        <div className="row">
                            <div className="col-3">
                                <label>Reporting period</label>
                                <Select
                                    styles={customStyles}
                                    options={this.props.options}
                                    onChange={option => this.props.handleSelect(option)}
                                    value={this.props.selectedValue}
                                />
                            </div>
                        </div>
                    </div>
                </Sticky>
            </div>
        )
    }
}

export default DashboardSub;
