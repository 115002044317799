import React, {Component} from 'react';
import Modal from 'react-bootstrap4-modal';
import {Circle} from "react-color/lib/components/circle/Circle";

class CreateLabelModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            description: '',
            labelColor: '#f44336'
        }
    }

    handleConfirm = () => {
        this.props.doCreateLabel(this.state)
        this.props.setModalStatus(false)
    }

    render() {
        return (
          <Modal className="dk-modal" visible={this.props.visible} onClickBackdrop={() => this.props.setModalStatus(false)}>
              <div className="modal-header">
                  <h5 className="modal-title text-white">Create new tag</h5>
              </div>
              <div className="modal-body">
                  <div className="input-group input-group-sm mb-2">
                      <div className="input-group-prepend ">
                          <span className="input-group-text" id="">Name</span>
                      </div>
                      <input onChange={(e) => this.setState({name: e.target.value})} value={this.state.name} type="text" className="form-control form-control-user"/>
                  </div>
                  <div className="input-group input-group-sm mb-2">
                      <div className="input-group-prepend">
                          <span className="input-group-text" id="">Description</span>
                      </div>
                      <input onChange={(e) => this.setState({description: e.target.value})} value={this.state.description} type="text" className="form-control form-control-user"/>
                  </div>
                  <Circle color={this.state.labelColor} onChange={color => this.setState({labelColor: color.hex})} width={'100%'}/>
              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.props.setModalStatus(false)}>
                      Cancel
                  </button>
                  <button type="button" className="btn btn-dark-green btn-sm" onClick={() => {this.handleConfirm()}}>
                      Create
                  </button>
              </div>
          </Modal>
        )
    }
}

export default CreateLabelModal