import React, {Component} from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import determineProjectTemplate from "../../../utils/projectTemplates/determineProjectTemplate";
import TemplateSection from "../reportsList/TemplateSection";

class SearchResultInfoPanel extends Component {

    render() {
        const hasImage = !!this.props.image;
        return (
            <SlidingPanel
                type={'right'}
                isOpen={this.props.isOpen}
                size={48}
                backdropClicked={() => this.props.closeSidePanel()}
            >
                <div className="panel-container">
                    <div className="panel-header">
                        {/*<span className="title">Search result: {hasImage && this.props.image.search_hash}</span>*/}
                        <div onClick={() => this.props.closeSidePanel()} className="white-close-icon"></div>
                    </div>
                    {/*<div className="img-container text-center">*/}
                    {/*    <img className="img-fluid" src={hasImage ? this.props.image.image_url : ""}/>*/}
                    {/*</div>*/}
                    <div className="panel-body svs-card">
                        {hasImage &&
                            determineProjectTemplate(this.props.image).sections.map(templateSection => {
                                return <TemplateSection
                                    key={templateSection.name}
                                    sectionTitle={templateSection.name}
                                    fields={templateSection.fields}
                                    forPanel={true}
                                    imageData={this.props.image}
                                    hasNotes={false}
                                    // fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                    setNotes={this.props.setNotes}
                                    saveNotes={this.props.saveNotes}
                                    notesValue={this.props.notesValue}
                                />
                            })
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }
}

export default SearchResultInfoPanel