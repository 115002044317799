import {toast} from "react-toastify";
import AuthAgent from '../libs/AuthAgent';
import axios from "axios";

export const SET_REVIEWED = 'set:reviewed';

export const setReviewed = (status) => {
    return {
        type: SET_REVIEWED,
        payload: {
            status
        }
    }
}

export const doReview = (searchResultsId, reviewStatus) => {
    return dispatch => {

        let payload = {
            id: searchResultsId,
            review: reviewStatus
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/review`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            dispatch(setReviewed(response.data.review));
            let reviewText = response.data.review ? 'reviewed' : 'not reviewed';
            toast('Search result marked as ' + reviewText)
        }).catch((error) => {
            toast('Something went wrong')
        })
    }
}
