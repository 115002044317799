class Validation {

    errors = {
        email: {
            hasErrors: 'not-validated',
            errorMessages: []
        },
        password: {
            hasErrors: 'not-validated',
            errorMessages: []
        },
        formHasErrors: 'not-validated'
    }

    validateLoginData(email, password) {
        this.validateLoginEmail(email)
        this.validateLoginPassword(password)

        return this.errors
    }

    validateLoginEmail(email) {

        this.errors.email.errorMessages = [];

        if(email === "") {
            this.errors.email.hasErrors = true;
            this.errors.email.errorMessages.push('Email field cannot be blank')
            this.errors.formHasErrors = true
            return this.errors
        }
        if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            this.errors.email.hasErrors = true;
            this.errors.email.errorMessages.push('Not a valid email address')
            this.errors.formHasErrors = true
            return this.errors
        }

        this.errors.email.hasErrors = false;
        // this.errors.email.errorMessages.push('Not a valid email address')
        this.errors.formHasErrors = false

        return this.errors
    }

    validateLoginPassword(password) {

        this.errors.password.errorMessages = [];

        if(password === "") {
            this.errors.password.hasErrors = true;
            this.errors.password.errorMessages.push('Password field cannot be blank')
            this.errors.formHasErrors = true
            return this.errors
        }

        this.errors.password.hasErrors = false;
        // this.errors.email.errorMessages.push('Not a valid email address')
        this.errors.formHasErrors = false

        return this.errors
    }

    getEmailErrorsStructure() {
        return this.errors.email
    }

    getPasswordErrorsStructure() {
        return this.errors.password
    }
}

export default new Validation()