import React, {Component} from 'react';
import Modal from 'react-bootstrap4-modal';
import CreatableSelect from 'react-select/creatable';
import {Link} from "react-router-dom";
import {ROUTES} from "../../../routes/frontend";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: (state.isFocused || state.isHover) && '1px solid #86bc25',
        boxShadow: state.isFocused && '0 0 0 0.2rem #86bc25',
        borderRadius: 0,
        '&:hover': {
            borderColor: '1px solid #86bc25'
        },
    }),
    dropdownIndicator: (provided, { data }) => ({
        ...provided,
        color: '#000'
    }),
    indicatorSeparator: (provided, { data }) => ({
        ...provided,
        display: 'none'
    }),
    // option: (provided, { data }) => ({
    //     ...provided,
    //     backgroundColor: `#${data.backgroundColor}`,
    //     color:  `#${data.textColor} !important`,
    //     fontSize: '14px'
    // }),
    singleValue: (provided, { data }) => ({
        ...provided,
        fontSize: '14px'
    }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: `#${data.backgroundColor}`,
            color: `#${data.textColor} !important`,
            fontSize: '14px'
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: `#${data.textColor} !important`,
    }),
    placeholder: (styles, { data }) => ({
        ...styles,
        fontSize: '14px'
    }),
}

class AddToListModal extends Component {

    handleAddToList = () => {
        this.props.addToList(this.props.items, this.props.selectedList.value)
        this.props.setVisible(false)
    }

    handleCreate = (val) => {
        this.props.doCreateList(val, '')
    }

    render() {
        return (
          <Modal className="dk-modal" visible={this.props.visible} onClickBackdrop={() => this.props.setVisible(false)}>
              <div className="modal-header">
              </div>
              <div className="modal-body">
                  <div className="d-flex justify-content-between align-items-center">
                      <h5 className="modal-title">Add to list</h5>
                      <div onClick={() => this.props.setVisible(false)} className="close-icon"></div>
                  </div>

                  <p className="modal-intro">Add the selected item(s) to an existing list or <Link to={ROUTES.userLists.path}>create a new one</Link>.</p>

                  <label>List</label>
                  <CreatableSelect
                      isClearable
                      placeholder="Choose list"
                      noOptionsMessage="No lists"
                      formatCreateLabel={userInput => `Create new list: ${userInput}`}
                      createOptionPosition="first"
                      onChange={selectedOption => this.props.onListSelect(selectedOption)}
                      onCreateOption={this.handleCreate}
                      styles={customStyles}
                      isLoading={this.props.userLists.fetching}
                      isDisabled={this.props.userLists.fetching}
                      options={this.props.userLists.userLists.map(list => {
                          return {label: list.title, value: list.id}
                      })}
                      value={this.props.userLists.selectedList}
                  />

              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.props.setVisible(false)}>
                      Cancel
                  </button>
                  <button type="button" className="btn btn-dark-green btn-sm text-white" onClick={() => {this.handleAddToList()}}>
                      Add
                  </button>
              </div>
          </Modal>
        )
    }
}

export default AddToListModal;