import React, {Component, Fragment} from 'react';
import Topbar from "../parts/layout/Topbar";
import Sidebar from "../parts/layout/Sidebar";
import {connect} from 'react-redux';
import ReportsListMenu from '../parts/reportsListMenu/ReportsListMenu';
import {
    doPrint,
    fetchIdsListForReportsList, fetchSingleViewImageData, setIndexIdsForNavigation,
} from "../../actions/reportsList";
import SingleSearchResultCardViewer from "../parts/reportsList/SingleSearchResultCardViewer";
import {doBookmark} from "../../actions/bookmarks";
import {saveNotes, setNotes} from "../../actions/notes";
import {doReview} from "../../actions/reviewed";
import {addToList, doCreateList, fetchUserListData, setSelectedList} from "../../actions/lists";
import {addLabelToSearchResult, removeLabelFromSearchResult} from "../../actions/labels";
import prepareFilters from "../../utils/filters/prepareFilters";
import ReportListSub from "../parts/layout/subheader/ReportListSub";
import {setAddToListModalToggle, setFilterPanelToggle} from "../../actions/reportListSubheader";
import AddToListModal from "../parts/reportsList/AddToListModal";

class ReportsList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            printModalVisibility: false
        }
    }

    componentDidMount() {

        let filters = prepareFilters(this.props.menu)

        this.props.fetchIdsListForReportsList(filters)
        this.props.fetchUserListData()
    }

    cycleNext = (e, currentIndex) => {

        e.preventDefault()

        let toBeCurrentIndex = currentIndex + 1;
        let toBeNextIndex = toBeCurrentIndex + 1;
        let toBePrevIndex = toBeCurrentIndex - 1;

        if (toBeCurrentIndex > this.props.reportsList.ids.length - 1) {
            toBeCurrentIndex = currentIndex;
        }

        this.props.setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex);
        this.props.fetchSingleViewImageData(this.props.reportsList.ids[toBeCurrentIndex])
    }

    cyclePrev = (e, currentIndex) => {

        e.preventDefault()

        let toBeCurrentIndex = currentIndex - 1;
        let toBeNextIndex = toBeCurrentIndex + 1;
        let toBePrevIndex = toBeCurrentIndex - 1;

        if (toBePrevIndex < 0) {
            toBePrevIndex = null;
        }

        this.props.setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex);
        this.props.fetchSingleViewImageData(this.props.reportsList.ids[toBeCurrentIndex])
    }

    render() {

        return (
            <div id="page-top">
                <div id="wrapper">
                    <Sidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar pageTitle={this.props.pageTitle}/>
                            <ReportListSub
                                setFilterPanelToggle={this.props.setFilterPanelToggle}
                                filtersPanelToggleValue={this.props.reportListSubheader.filtersTogglePanel}
                                setAddToListModalToggle={this.props.setAddToListModalToggle}
                                addToListModalToggleValue={this.props.reportListSubheader.addToListModalToggle}
                                existingLabels={this.props.labels.list}
                                imageData={this.props.reportsList.imageData}
                                addLabelToSearchResult={this.props.addLabelToSearchResult}
                            />
                            <ReportsListMenu
                                setFilterPanelToggle={this.props.setFilterPanelToggle}
                                visible={this.props.reportListSubheader.filtersTogglePanel}
                                setAddToListModalToggle={this.props.setAddToListModalToggle}
                                fetchResults={this.props.fetchIdsListForReportsList}
                                setCurrentPage={() => null}
                                pagination={{
                                    currentPageValue: this.props.menu.currentPageValue,
                                    itemsCountPerPage: this.props.menu.itemsCountPerPage,
                                }}
                            />
                            <div className="container-fluid">
                                {this.props.reportsList.fetchingIds ?
                                    <div className="row h-100">
                                        <div className="col-12 h-100 d-flex align-items-center justify-content-center text-primary">
                                            <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                        {this.props.reportsList.ids.length > 0 ?
                                            <div className="row">
                                                <div className="col-12">
                                                    {this.props.reportsList.imageData &&
                                                        <>
                                                            <SingleSearchResultCardViewer
                                                                imageData={this.props.reportsList.imageData}
                                                                fetching={this.props.reportsList.fetching}
                                                                fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                                                currentIndex={this.props.reportsList.currentIdIndex}
                                                                listLength={this.props.reportsList.ids.length}
                                                                doBookmark={this.props.doBookmark}
                                                                setNotes={this.props.setNotes}
                                                                saveNotes={this.props.saveNotes}
                                                                notesValue={this.props.reportsList.notes}
                                                                doReview={this.props.doReview}
                                                                userLists={this.props.userLists.userLists}
                                                                addToList={this.props.addToList}
                                                                hasRemoveFromList={false}
                                                                removeLabelFromSearchResult={this.props.removeLabelFromSearchResult}
                                                                addLabelToSearchResult={this.props.addLabelToSearchResult}
                                                                existingLabels={this.props.labels.list}
                                                            />
                                                            <div className="row">
                                                                <div className="col-12 mb-3">
                                                                    <div className="col-12 text-center">
                                                                        <button
                                                                            disabled={this.props.reportsList.previousIdIndex === null ? true : false}
                                                                            className="btn btn-secondary mr-2 d-inline"
                                                                            onClick={(e) => this.cyclePrev(e, this.props.reportsList.currentIdIndex)}>
                                                                            Prev
                                                                        </button>
                                                                        <button
                                                                            disabled={this.props.reportsList.currentIdIndex === this.props.reportsList.ids.length - 1 ? true : false}
                                                                            className="btn btn-secondary ml-2 d-inline"
                                                                            onClick={(e) => this.cycleNext(e, this.props.reportsList.currentIdIndex)}>
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col m-0 text-center">
                                                                    <span className="mb-0">{this.props.reportsList.currentIdIndex + 1} of {this.props.reportsList.ids.length} results</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-6 offset-3 text-center">
                                                    <div className="alert alert-info">No search results matching your
                                                        filters.
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    {/*<PrintModal*/}
                    {/*  visibility={this.state.printModalVisibility}*/}
                    {/*  toggleModal={() => {this.setState(prevState => ({printModalVisibility: !prevState.printModalVisibility}))}}*/}
                    {/*  doPrint={this.props.doPrint}*/}
                    {/*  srIds={this.props.reportsList.ids}*/}
                    {/*/>*/}
                    <AddToListModal
                        visible={this.props.reportListSubheader.addToListModalToggle}
                        setVisible={this.props.setAddToListModalToggle}
                        setModalStatus={this.props.setAddToListModalToggle}
                        userLists={this.props.userLists}
                        selectedList={this.props.userLists.selectedList}
                        onListSelect={this.props.setSelectedList}
                        addToList={this.props.addToList}
                        items={this.props.reportsList.currentId}
                        doCreateList={this.props.doCreateList}
                        fetchUserListData={this.props.fetchUserListData}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reportsList: state.reportsList,
        menu: state.reportsListMenu,
        projectList: state.projectList,
        userLists: state.userLists,
        labels: state.labels,
        reportListSubheader: state.reportListSubheader
    }
};

const mapActionToProps = (dispatch) => {
    return {
        setFilterPanelToggle: (status) => {
            dispatch(setFilterPanelToggle(status))
        },
        setAddToListModalToggle: (status) => {
            dispatch(setAddToListModalToggle(status))
        },
        fetchIdsListForReportsList: (srStatus) => {
            dispatch(fetchIdsListForReportsList(srStatus))
        },
        // fetchUpdatedFeatures: (e, featureId, searchHash) => {
        //     dispatch(fetchUpdatedFeatures(e, featureId, searchHash))
        // },
        setIndexIdsForNavigation: (currentIndex, nextIndex, prevIndex) => {
            dispatch(setIndexIdsForNavigation(currentIndex, nextIndex, prevIndex))
        },
        fetchSingleViewImageData: (searchResultId) => {
            dispatch(fetchSingleViewImageData(searchResultId))
        },
        doBookmark: (searchResultsId, bookmarkStatus) => {
            dispatch(doBookmark(searchResultsId, bookmarkStatus))
        },
        // Notes
        setNotes: (notes) => {
            dispatch(setNotes(notes))
        },
        saveNotes: (searchResultId, notes) => {
            dispatch(saveNotes(searchResultId, notes))
        },
        doReview: (searchResultId, reviewStatus) => {
            dispatch(doReview(searchResultId, reviewStatus))
        },
        fetchUserListData: () => {
            dispatch(fetchUserListData())
        },
        addToList: (searchResultId, listId) => {
            dispatch(addToList(searchResultId, listId))
        },
        doCreateList: (listName, listDesc) => {
            dispatch(doCreateList(listName, listDesc))
        },
        setSelectedList: (selectedList) => {
            dispatch(setSelectedList(selectedList))
        },
        doPrint: (isUserDefinedList, searchResultIds, listName, listDesc) => {
            dispatch(doPrint(isUserDefinedList, searchResultIds, listName, listDesc))
        },
        removeLabelFromSearchResult: (label, searchResultId) => {
            dispatch(removeLabelFromSearchResult(label, searchResultId))
        },
        addLabelToSearchResult: (label, searchResultId) => {
            dispatch(addLabelToSearchResult(label, searchResultId))
        }
    }
};

export default connect(mapStateToProps, mapActionToProps)(ReportsList)
