import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {getStatusFilterOptions} from '../../../utils/dataSelection/statusFilterOptions';
import DatePicker from 'react-datepicker';
import {
    setStatus, setHost, setPropertyType,
    setDkSellerRating, setFeature, setSort, setEndDate,
    setStartDate, setTextSearch, setNotesSearch, setLabel, resetFilters
} from "../../../actions/reportsListMenu";
import {
    fetchIdsListForReportsList,
} from "../../../actions/reportsList";
import SlidingPanel from 'react-sliding-side-panel';

// Options for select inputs
import getPropertyTypeFilterOptions from "../../../utils/dataSelection/propertyTypeFilterOptions";
// import getDkSellerRatingFilterOptions from "../../../utils/dataSelection/dkSellerRatingFilterOptions";
// import getFeatureOptions from "../../../utils/dataSelection/featuresOptions";
// import getActionRecommendedOptions from "../../../utils/dataSelection/actionRecommendedOptions";
// import {getBookmarkOptions} from "../../../utils/dataSelection/bookmarkOptions";
// import {getReviewedByClientOptions} from "../../../utils/dataSelection/reviewedByClientOptions";
// import {getProject} from "../../../utils/project/project";
import {fetchLabels} from "../../../actions/labels";
import prepareFilters from "../../../utils/filters/prepareFilters";
import {initialState} from "../../../reducers/reportsListMenuReducer";

import ScrollLock, { TouchScrollable } from 'react-scrolllock';


const customStyles = {
    control: (provided, state) => ({
      ...provided,
        border: (state.isFocused || state.isHover) && '1px solid #86bc25',
        boxShadow: state.isFocused && '0 0 0 0.2rem #86bc25',
        borderRadius: 0,
        '&:hover': {
            borderColor: '1px solid #86bc25'
        },
    }),
    dropdownIndicator: (provided, { data }) => ({
        ...provided,
        color: '#000'
    }),
    indicatorSeparator: (provided, { data }) => ({
        ...provided,
        display: 'none'
    }),
    option: (provided, { data }) => ({
        ...provided,
        backgroundColor: `#${data.backgroundColor}`,
        color:  `#${data.textColor} !important`,
        fontSize: '14px'
    }),
    singleValue: (provided, { data }) => ({
        ...provided,
        fontSize: '14px'
    }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: `#${data.backgroundColor}`,
            color: `#${data.textColor} !important`,
            fontSize: '14px'
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: `#${data.textColor} !important`,
    }),
    placeholder: (styles, { data }) => ({
        ...styles,
        fontSize: '14px'
    }),
}

class ReportsListMenu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            toggleFilterMenu: false
        }

        this.ref = React.createRef();
    }

    componentDidMount() {

        if (this.props.menu.remoteStatusValue !== -1) {
            this.props.setCurrentPage(1)
            this.props.setStatus(getStatusFilterOptions()[0])
        }

        this.props.fetchLabels()
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };

    handleClickOutside = (event) => {
        if (this.ref.current !== null && !this.ref.current.contains(event.target)) {
            this.props.setFilterPanelToggle(false)
        }
    };

    onApplyFilters = (e) => {

        e.preventDefault()

        let filters = prepareFilters(this.props.menu)
        this.props.setCurrentPage(1)
        filters.currentPageValue = this.props.pagination.currentPageValue
        filters.itemsCountPerPage = this.props.pagination.itemsCountPerPage

        this.props.fetchResults(filters, [])

        this.props.setFilterPanelToggle(false)
    }

    handleFilterPanelToggleClass = () => {
        return this.props.visible ? 'card report-list-menu open-rlm' : 'card report-list-menu shadow-lg close-rlm'
    }

    render() {
        return (
            <SlidingPanel
                type={'right'}
                size={0}
                isOpen={this.props.visible}
            >
            <div className="filters-panel-container">
                <div ref={this.ref} className={this.handleFilterPanelToggleClass()}>

                    {/*<div onClick={(e) => this.setState((prevState => ({toggleFilterMenu: !prevState.toggleFilterMenu})))}*/}
                    {/*     className="panel-toggle filter text-center">*/}
                    {/*    <i className="fas fa-filter mr-1"></i><span>Filters</span>*/}
                    {/*</div>*/}

                    <div className="card-header">
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="filters-panel-section">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <h5 className="mb-0">Filter</h5>
                                            <div onClick={() => this.props.setFilterPanelToggle(false)} className="close-icon"></div>
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Reporting Period</label>
                                            <Select
                                                value={this.props.menu.status}
                                                onChange={(selectedOption) => this.props.setStatus(selectedOption)}
                                                options={getStatusFilterOptions()}
                                                placeholder={"Please select"}
                                                styles={customStyles}
                                            />
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>General Search</label>
                                            <div className="form-group">
                                                <div className="input-container-text">
                                                    <input type="text" onChange={(e) => {
                                                        this.props.setTextSearch(e.target.value)
                                                    }} className="form-control form-control-user" value={this.props.menu.textSearch}
                                                           disabled={false} placeholder="Search"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Property Type</label>
                                            <Select
                                                value={this.props.menu.propertyType}
                                                onChange={(selectedOption) => this.props.setPropertyType(selectedOption)}
                                                options={getPropertyTypeFilterOptions()}
                                                isMulti
                                                placeholder={"Please select"}
                                                styles={customStyles}
                                            />
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Notes Search</label>
                                            <div className="form-group">
                                                <div className="input-container-text">
                                                    <input type="text" onChange={(e) => {
                                                        this.props.setNotesSearch(e.target.value)
                                                    }} className="form-control form-control-user" value={this.props.menu.notesSearch}
                                                           disabled={false} placeholder="Search"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Tags</label>
                                            <Select
                                                value={this.props.menu.labels}
                                                onChange={(selectedOption) => this.props.setLabel(selectedOption)}
                                                options={this.props.labels.list.map(label =>{
                                                    return {label: label.name, value: label.id, backgroundColor: label.label_color, textColor: label.text_color}
                                                })}
                                                placeholder={"Please select"}
                                                styles={customStyles}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Domain Search</label>
                                            <div className="form-group">
                                                <div className="input-container-text">
                                                    <input type="text" onChange={(e) => {
                                                        this.props.setHost(e.target.value)
                                                    }} className="form-control form-control-user" value={this.props.menu.host}
                                                           disabled={false} placeholder="Search"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="col-4">*/}
                                        {/*    <Select*/}
                                        {/*      value={this.props.menu.dkSellerRating}*/}
                                        {/*      onChange={(selectedOption) => this.props.setDkSellerRating(selectedOption)}*/}
                                        {/*      options={getDkSellerRatingFilterOptions()}*/}
                                        {/*      isMulti*/}
                                        {/*      placeholder={"DK seller rating"}*/}
                                        {/*      styles={customStyles}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*{hasFeatures &&*/}
                                        {/*<div className="col-4">*/}
                                        {/*    <Select*/}
                                        {/*      value={this.props.menu.features}*/}
                                        {/*      onChange={(selectedOption) => this.props.setFeature(selectedOption)}*/}
                                        {/*      options={getFeatureOptions()}*/}
                                        {/*      isMulti*/}
                                        {/*      placeholder={"Tags"}*/}
                                        {/*      styles={customStyles}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*}*/}
                                        {/*<div className="col-4 mb-3">*/}
                                        {/*    <Select*/}
                                        {/*      value={this.props.menu.actionRecommended}*/}
                                        {/*      onChange={(selectedOption) => this.props.setActionRecommended(selectedOption)}*/}
                                        {/*      options={getActionRecommendedOptions()}*/}
                                        {/*      placeholder={"Action recommended"}*/}
                                        {/*      styles={customStyles}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<div className="col-4">*/}
                                        {/*    <Select*/}
                                        {/*      value={this.props.menu.bookmarked}*/}
                                        {/*      onChange={(selectedOption) => this.props.setBookmarked(selectedOption)}*/}
                                        {/*      options={getBookmarkOptions()}*/}
                                        {/*      placeholder={"Bookmarked"}*/}
                                        {/*      styles={customStyles}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<div className="col-4">*/}
                                        {/*    <Select*/}
                                        {/*        value={this.props.menu.reviewedByClient}*/}
                                        {/*        onChange={(selectedOption) => this.props.setReviewedByClient(selectedOption)}*/}
                                        {/*        options={getReviewedByClientOptions()}*/}
                                        {/*        placeholder={"Reviewed"}*/}
                                        {/*        styles={customStyles}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Date From</label>
                                            <div className="input-container-date">
                                                <DatePicker
                                                    selected={this.props.menu.startDate}
                                                    onChange={date => this.props.setStartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="Select date"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Date To</label>
                                            <div className="input-container-date">
                                                <DatePicker
                                                    selected={this.props.menu.endDate}
                                                    onChange={date => this.props.setEndDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="Select date"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="filters-panel-section">
                                    <div className="row">
                                        <div className="col-12 d-flex">
                                            <h5 className="mb-0">Sort</h5>
                                        </div>
                                        <div className="col-6 filters-panel-section-item">
                                            <label>Sort By</label>
                                            <Select
                                                value={this.props.menu.sortKey}
                                                menuPlacement={"auto"}
                                                onChange={(selectedOption) => this.props.setSort(selectedOption)}
                                                options={[
                                                    {value: 'no-sort', label: 'None'},
                                                    {value: 'date_found_desc', label: 'Date found: newest to oldest'},
                                                    {value: 'date_found_asc', label: 'Date found: oldest to newest'},
                                                    {value: 'domain_az', label: 'Domain: A-Z'},
                                                    {value: 'domain_za', label: 'Domain: Z-A'},
                                                    {value: 'monthly_visits_lh', label: 'Monthly visits: low to high'},
                                                    {value: 'monthly_visits_hl', label: 'Monthly visits: high to low'},
                                                ]}
                                                placeholder={"None"}
                                                styles={customStyles}
                                            />
                                        </div>
                                        <hr />
                                    </div>
                               </div>
                            </div>
                            <div className="col-12 text-right">
                                <button className="btn btn-secondary ml-3 mt-3" onClick={() => this.props.resetFilters(initialState)}>Reset All</button>
                                <button className="btn btn-dark-green ml-3 mt-3" onClick={(e) => this.onApplyFilters(e)}>Apply
                                    filters
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </SlidingPanel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu: state.reportsListMenu,
        reportsList: state.reportsList,
        labels: state.labels,
        reportListSubheader: state.reportListSubheader
    }
};

const mapActionToProps = (dispatch) => {
    return {

        // Filters
        setStatus: (statusValue) => {
            dispatch(setStatus(statusValue))
        },
        setHost: (hostValue) => {
            dispatch(setHost(hostValue))
        },
        setTextSearch: (hostValue) => {
            dispatch(setTextSearch(hostValue))
        },
        setNotesSearch: (hostValue) => {
            dispatch(setNotesSearch(hostValue))
        },
        fetchIdsListForReportsList: (filters) => {
            dispatch(fetchIdsListForReportsList(filters))
        },
        setPropertyType: (propertyType) => {
            dispatch(setPropertyType(propertyType))
        },
        setDkSellerRating: (dkSellerRating) => {
            dispatch(setDkSellerRating(dkSellerRating))
        },
        setFeature: (selectedFeature) => {
            dispatch(setFeature(selectedFeature))
        },
        setStartDate: (startDate) => {
            dispatch(setStartDate(startDate))
        },
        setEndDate: (endDate) => {
            dispatch(setEndDate(endDate))
        },
        setLabel: label => {
            dispatch(setLabel(label))
        },
        fetchLabels: () => {
            dispatch(fetchLabels())
        },
        resetFilters: (defaultFilterValues) => {
            dispatch(resetFilters(defaultFilterValues))
        },
        // setActionRecommended: (selectedAction) => {
        //     dispatch(setActionRecommended(selectedAction))
        // },
        // setBookmarked: (bookmarkStatus) => {
        //     dispatch(setBookmarked(bookmarkStatus))
        // },
        // setReviewedByClient: (reviewedStatus) => {
        //     dispatch(setReviewedByClient(reviewedStatus))
        // },

        // SORTING
        setSort: (sortKey) => {
            dispatch(setSort(sortKey))
        },
    }
};

export default connect(mapStateToProps, mapActionToProps)(ReportsListMenu);
