import React from "react";
import { useMsal } from "@azure/msal-react";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const LogoutButton = (props) => {

    const { instance } = useMsal();

    const handleLogout = (e) => {

        e.preventDefault()

        try {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/login",
            });
            props.handleLogout()
        } catch (e) {
            console.log(e)
        }
    }

    return (
      <span className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"
            role="button" onClick={(e) => handleLogout(e)}>
                              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                              Log out
                          </span>
    )
}

export default LogoutButton;