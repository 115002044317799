import * as authActions from '../actions/authentication'

const initialState = {
    token: null,
    fetching: false,
    authError: false,
    errorMessage: ''
};

export default function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case authActions.SET_FETCHING_AUTH:
            return {
                ...state,
                fetching: action.payload.status
            }
        case authActions.SET_ERROR_MESSAGE_AND_STATUS:
            return {
                ...state,
                authError: action.payload.status,
                errorMessage: action.payload.message
            }
        // case authActions.LOGOUT:
        //
        //
        //     return state = undefined
        default:
            return state
    }
}
