import React, {Component} from "react";

class WordCloud extends Component {
    render() {
        return (
          <div className="card">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold">Word cloud</h6>
              </div>
              <div className="card-body">
                  {/*<h4>WE ANALYSED SOCIAL PRODUCT DESCRIPTIONS AND USE THE DATA TO FIND MORE DUPES</h4>*/}
                  {/*<p>The word cloud shows the relative frequency of words used in product descriptions of Dupes.*/}
                  {/*    We use this data to improve the specificity of our searches and identify any language which is specific to dupes.</p>*/}
                  {/*<hr />*/}
                  <div className="text-center">
                      <img className="img-fluid" src={this.props.img} alt="wordcloud"/>
                  </div>
              </div>
          </div>
        )
    }
}

export default WordCloud;