import React, {Component} from 'react';
import Sidebar from '../parts/layout/Sidebar';
import Topbar from '../parts/layout/Topbar';
import {connect} from 'react-redux';
import {doCreateList, fetchUserListData} from '../../actions/lists';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import moment from 'moment';
import Header from '../parts/lists/Header';
import Actions from "../parts/lists/Actions";
import CreateListModal from "../parts/lists/CreateListModal";
import {Link} from "react-router-dom"

const columns = memoize(() => [
    {
        name: 'Name',
        selector: 'title',
        cell: row => <Link to={`list/${row.id}`}>{row.title}</Link>,
        maxWidth: '300px',
    },
    {
        name: 'Description',
        selector: 'description',
        maxWidth: '300px',
    },
    {
        name: 'Items',
        selector: 'item_count',
        maxWidth: '300px',
    },
    {
        name: 'Created',
        // selector: 'created',
        // maxWidth: '300px',
        cell: row => moment(row.created).format('DD/MM/YYYY')
    },
    {
        name: 'Modified',
        // selector: 'created',
        // maxWidth: '300px',
        cell: row => moment(row.modified).format('DD/MM/YYYY')
    },
    {
        name: 'Actions',
        // selector: 'created',
        maxWidth: '150px',
        cell: row => <Actions row={row} />,
        center: true
    },
]);

class Lists extends Component {

    constructor(props) {
        super(props)

        this.state = {
            filteredText: '',
            visible: false,
            listName: '',
            listDesc: ''
        }
    }

    componentDidMount() {
        this.props.fetchUserListData()
    }

    setModalStatus = (status) => {
        this.setState({
            visible: status
        })
    }

    render() {
        const filteredItems = this.props.userLists.userLists.filter(item => item.title && item.title.includes(this.state.filteredText));

        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar/>
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="user-lists">
                          <Topbar pageTitle={this.props.pageTitle} />
                          <div className="container-fluid no-subheader">
                              <div className="user-lists-table">
                                  <DataTable
                                    className="ul-table"
                                    columns={columns()}
                                    data={filteredItems}
                                    keyField={'id'}
                                    pagination
                                    paginationPerPage={30}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 75, 100]}
                                    defaultSortField={'created'}
                                    defaultSortAsc={false}
                                    noHeader
                                    subHeader
                                    subHeaderComponent={
                                        <Header
                                          buttonTitle="New list"
                                          value={this.state.filteredText}
                                          handleOnFilterTextChange={(filteredText) => this.setState({filteredText})}
                                          setModalStatus={this.setModalStatus}
                                        />}
                                  />
                              </div>
                          </div>
                          <CreateListModal
                            visible={this.state.visible}
                            setModalStatus={this.setModalStatus}
                            nameValue={this.state.listName}
                            descValue={this.state.listDesc}
                            handleOnChangeListName={(nameValue) => this.setState({listName: nameValue})}
                            handleOnChangeListDesc={(descValue) => this.setState({listDesc: descValue})}
                            doCreateList={this.props.doCreateList}
                          />
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userLists: state.userLists,
    }
};

const mapActionsToProps = dispatch => {
    return {
        fetchUserListData: () => {
            dispatch(fetchUserListData())
        },
        doCreateList: (listName, listDesc) => {
            dispatch(doCreateList(listName, listDesc))
        },
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Lists);
