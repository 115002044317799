import AuthAgent from "../libs/AuthAgent";
import axios from "axios";

export const SET_SUMMARY_LIST = "summary:setList";
export const SET_FETCHING = "summary:setFetching";
export const SET_SUMMARY = "summary:set";
export const SET_SUMMARY_OPTION = "summary:setOption"

export const setSummaryList = (list) => {
    return {
        type: SET_SUMMARY_LIST,
        payload: {
            list
        }
    }
}

export const setFetching = (status) => {
    return {
        type: SET_FETCHING,
        payload: {
            status
        }
    }
}

export const setSummary = (summary) => {
    return {
        type: SET_SUMMARY,
        payload: {
            summary
        }
    }
}

export const setSummaryOption = (option) => {
    return {
        type: SET_SUMMARY_OPTION,
        payload: {
            option
        }
    }
}

export const doFetchSummaryList = () => {
    return dispatch => {
        dispatch(setFetching(true))
        let projectId = JSON.parse(localStorage.getItem('project')).id

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/summary/list/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setSummaryList(response.data))
            if (response.data.length > 0) {
                dispatch(setSummaryOption({value: response.data[0].id, label: response.data[0].title}))
                dispatch(doFetchSummary(response.data[0].id))
            }
            dispatch(setFetching(false))
        }).catch((error) => {
            dispatch(setFetching(false))
            console.log(error.message)
        })
    }
}

export const doFetchSummary = (summaryId) => {
    return dispatch => {
        dispatch(setFetching(true))
        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/summary/${summaryId}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setSummary(response.data))
            dispatch(setFetching(false))
        }).catch((error) => {
            dispatch(setFetching(false))
            console.log(error.message)
        })
    }
}
