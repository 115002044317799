import React from 'react';

class Label extends React.Component {

    render() {
        return (
            <div key={this.props.label.id} className="d-inline-block mr-2 my-2">
                <span style={{
                    backgroundColor: `#${this.props.label.label_color}`,
                    color: `#${this.props.label.text_color}`
                }} className="label-text">{this.props.label.name}</span>

                <span style={{
                    backgroundColor: `#${this.props.label.label_color}`,
                    color: `#${this.props.label.text_color}`
                }} onClick={() => this.props.handleRemove(this.props.label)} className="label-remove remove">
                    <i className="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
        )
    }
}

export default Label