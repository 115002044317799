export function getPaginationItemsCountOptions() {
    return [
        // {value: 5, label: "5"},
        {value: 10, label: "10"},
        {value: 20, label: "20"},
        {value: 40, label: "40"},
        {value: 100, label: "100"},
        {value: 200, label: "200"},
        {value: 500, label: "500"},
        {value: 1000, label: "1000"}
    ]
}