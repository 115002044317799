import {toast} from "react-toastify";
import axios from "axios";

export const SET_PROJECT_LIST = 'projects:setList';
export const SET_FETCHING_PROJECT_LIST = 'project:setFetching'

export const setProjectList = list => {
    return {
        type: SET_PROJECT_LIST,
        payload: {
            list
        }
    }
}

export const setFetching = status => {
    return {
        type: SET_FETCHING_PROJECT_LIST,
        payload: {
            status
        }
    }
}

export const fetchProjectList = () => {
    return dispatch => {

        dispatch(setFetching(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/list-projects",
            withCredentials: true,
            method: 'GET',
        }).then(response => {
            dispatch(setProjectList(response.data));
            dispatch(setFetching(false));
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status ===422) {
                toast('Login expired. Redirecting to login.')
                dispatch(setFetching(false));
                return
            }
            dispatch(setFetching(false));
            toast('Something went wrong')

        })
    }
}
