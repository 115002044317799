import AuthAgent from '../libs/AuthAgent';
import {toast} from "react-toastify";
import axios from "axios";

export const SET_IDS_LIST = "reportsList:setIdsList";
export const SET_FETCHING_IDS = "reportsList:setFetchingIds";
export const SET_INDEX_IDS_FOR_NAVIGATION = "reportsList:setIndexIdsForNavigation";
export const SET_IDS_FOR_NAVIGATION = "reportsList:setIdsForNavigation";
export const SET_SINGLE_VIEW_IMAGE_DATA = "reportsList:setImageData";
export const SET_FETCHING_SINGLE_VIEW_IMAGE_DATA = "reportsList:setFetchingImageData";

export function setIdsList(idsList) {
    return {
        type: SET_IDS_LIST,
        payload: {
            idsList
        }
    }
}

export function setFetchingIds(status) {
    return {
        type: SET_FETCHING_IDS,
        payload: {
            status
        }
    }
}

export function setIndexIdsForNavigation(currentIdIndex, nextIdIndex, prevIdIndex) {
    return {
        type: SET_INDEX_IDS_FOR_NAVIGATION,
        payload: {
            currentIdIndex,
            nextIdIndex,
            prevIdIndex
        }
    }
}

export function setIdsForNavigation(currentId, nextId, prevId) {
    return {
        type: SET_IDS_FOR_NAVIGATION,
        payload: {
            currentId,
            nextId,
            prevId
        }
    }
}

export function setSingleViewImageData(imageData) {
    return {
        type: SET_SINGLE_VIEW_IMAGE_DATA,
        payload: {
            imageData
        }
    }
}

export function setFetchingSingleViewImageData(status) {
    return {
        type: SET_FETCHING_SINGLE_VIEW_IMAGE_DATA,
        payload: {
            status
        }
    }
}

export function fetchIdsListForReportsList(filters) {
    return dispatch => {
        dispatch(setFetchingIds(true))

        if (filters.startDate !== null) {
            filters.startDate = filters.startDate.toISOString()
        }

        if (filters.endDate !== null) {
            filters.endDate = filters.endDate.toISOString()
        }

        let payload = {
            filters: filters,
            project_id: JSON.parse(localStorage.getItem('project')).id
        };

        axios(process.env.REACT_APP_API_BASE_URL + "/search-result/single-view-shortlist", {
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {

            dispatch(setIdsList(response.data))

            let currentIndex = 0
            let currentId = response.data[currentIndex];

            let nextIndex = currentIndex === response.data.length -1 ? null : currentIndex + 1;
            let nextId = response.data[nextIndex];

            let prevIndex = null
            let prevId = null

            dispatch(setIndexIdsForNavigation(currentIndex, nextIndex, prevIndex));
            dispatch(setIdsForNavigation(currentId, nextId, prevId));

            if (response.data.length > 0) {
                dispatch(fetchSingleViewImageData(currentId))
            }
            dispatch(setFetchingIds(false))

        }).catch(error => {
            dispatch(setFetchingIds(false))
            console.log(error.message)
        })
    }
}

export function fetchSingleViewImageData(searchResultId) {
    return dispatch => {

        dispatch(setFetchingSingleViewImageData(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/${searchResultId}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setSingleViewImageData(response.data))
            dispatch(setFetchingSingleViewImageData(false))
        }).catch(error => {
            dispatch(setFetchingSingleViewImageData(false))
            console.log(error.message)
        })
    }
}

export const doPrint = (isUserDefinedList, searchResultIds, listName, listDesc) => {
    return dispatch => {

        let payload = {
            is_user_defined_list: isUserDefinedList,
            search_result_ids: searchResultIds || [],
            title: listName || '',
            description: listDesc || '',
            project_id: JSON.parse(localStorage.getItem('project')).id
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/report-list/print",
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(() => {
            toast('A new PDF report entry has been created in the PDF reports section.')
        }).catch(error => {
            toast('Could not create PDF report.')
            console.log(error.message)
        })

    }
}
