import React, {Component, Fragment} from 'react';
import SvsFeatures from "./SvsFeatures";
import {handleDataMappings} from '../../../utils/dataMappings/handleDataMappings'
import moment from "moment";
import SvsNotes from "./SvsNotes";
import BlockImage from 'react-block-image'
import placeHolderImage from '../../../assets/images/no-image-placeholder_DP.jpg';

class TemplateSection extends Component {

    render() {
        let labelCol = '6 label-col';
        let valueCol = '6 value-col';

        return (
          <Fragment>
              {this.props.fields.length > 0 &&
                <Fragment>
              <div className="row">
                  <div className="col-12 section-title-col">
                      <h4 className="mb-0 image-title">{this.props.sectionTitle}</h4>
                  </div>
              </div>
              {this.props.fields.map((data) => {
                  if (data.dataKey === 'image_url') {
                      return (
                        <div key={data.dataKey} className="row image-row">
                            <div className="col-12 image-col">
                                <BlockImage fallback={placeHolderImage} src={this.props.imageData[data.dataKey]} backgroundSize='contain' />
                            </div>
                        </div>
                      )
                  }

                  if (data.dataKey === 'image_source') {
                      return (
                        <div key={data.dataKey} className="row">
                            <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                            <div className={`col-${valueCol}`}>
                                {this.props.imageData[data.dataKey] === null ? '' : <a rel="noopener noreferrer" target="_blank" href={this.props.imageData[data.dataKey]}>{this.props.imageData[data.dataKey]}</a>}
                            </div>
                        </div>
                      )
                  }

                  if (data.dataKey === 'image_host') {

                      let label = this.props.imageData[data.dataKey].replace('https://', '').replace('www.', '').replace('http://', '')

                      return (
                          <div key={data.dataKey} className="row">
                              <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                              <div className={`col-${valueCol}`}>
                                  <a rel="noopener noreferrer" target="_blank" href={`//${this.props.imageData[data.dataKey]}`}>{label}</a>
                              </div>
                          </div>
                      )
                  }

                  if (data.dataKey === 'notes') {
                      return (
                        <SvsNotes
                          id={this.props.imageData.id}
                          key={this.props.imageData.id}
                          notesData={this.props.imageData[data.dataKey]}
                          labelCol={labelCol}
                          valueCol={valueCol}
                          setNotes={this.props.setNotes}
                          saveNotes={this.props.saveNotes}
                          notesValue={this.props.notesValue}
                        />
                      )
                  }

                  if (data.dataKey === 'date_presented_to_client') {
                      return (
                        <div key={data.dataKey} className="row">
                            <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                            <div className={`col-${valueCol}`}>
                                <p>{this.props.imageData[data.dataKey] === null ? handleDataMappings(data.dataKey, this.props.imageData[data.dataKey]) : moment(handleDataMappings(data.dataKey, this.props.imageData[data.dataKey])).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>
                      )
                  }

                  if (data.dataKey === 'image_host') {
                      return (
                        <div key={data.dataKey} className="row">
                            <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                            <div className={`col-${valueCol}`}><a rel="noopener noreferrer" target="_blank"
                                                                  href={'//' + this.props.imageData[data.dataKey]}>{this.props.imageData[data.dataKey] === null ? '' : handleDataMappings(data.dataKey, this.props.imageData[data.dataKey])}</a>
                            </div>
                        </div>
                      )
                  }

                  if (data.dataKey === 'features') {
                      return (
                        <SvsFeatures key={data.dataKey} imageData={this.props.imageData}
                                     fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}/>
                      )
                  }

                  if (data.dataKey !== 'features') {
                      return (
                        <div key={data.dataKey} className="row">
                            <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                            <div className={`col-${valueCol}`}>
                                <p>{this.props.imageData[data.dataKey] === null ? handleDataMappings(data.dataKey, this.props.imageData[data.dataKey]) : handleDataMappings(data.dataKey, this.props.imageData[data.dataKey])}</p>
                            </div>
                        </div>
                      )
                  }

                  return null
              })}
                </Fragment>
              }
          </Fragment>
        )


    }
}

export default TemplateSection;