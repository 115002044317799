import React, {Component} from "react";
import {formatNumberAsIntl} from "../../../../utils/various/format";
import moment from "moment";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../../routes/frontend";
import {getStatusFilterOptions} from "../../../../utils/dataSelection/statusFilterOptions";
import {initialState} from "../../../../reducers/reportsListMenuReducer";


class ExecutiveSummary extends Component {

    onTopWebsiteClick = (e) => {

        e.preventDefault()
        this.props.resetFilters(initialState)
        this.props.setRemoteStatus(getStatusFilterOptions()[0])
        this.props.setStartDate(new Date(this.props.summary.from))
        this.props.setEndDate(new Date(this.props.summary.to))
        this.props.router.push(ROUTES.galleryList.path)
    }

    render() {

        let project = JSON.parse(localStorage.getItem('project'));

        return (
            <div className="card executive-summary">
                <div className="card-header py-3 d-flex align-items-center justify-content-between">
                    <h6 className="m-0 card-title font-weight-bold">Executive summary</h6>
                    <h6 className="m-0 card-report-date">{`${moment(this.props.summary.from).format('DD/MM/YYYY')} - ${moment(this.props.summary.to).format('DD/MM/YYYY')}`}</h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-6 dupes d-flex justify-content-center align-items-center flex-column text-center py-5">
                            <p>We found <span className="is-pointer text-primary" onClick={(e) => this.onTopWebsiteClick(e)}>{formatNumberAsIntl(this.props.summary.summary_data.dupe_count)}</span> dupes</p>
                            <p> across <span>{formatNumberAsIntl((this.props.summary.summary_data.pages_scanned))}</span> scanned pages</p>
                            <p>The top website on which a dupe was found is:</p>
                            <a className="top-website" target="_blank" rel="noopener noreferrer" href={"//" + this.props.topWebsitesByCount[0].domain}>{this.props.topWebsitesByCount[0].domain}</a>
                            <div className="d-flex mt-3">
                                <Link to={ROUTES.galleryList.path} className="btn btn-secondary mr-2">{`Go to ${ROUTES.galleryList.displayName}`}</Link>
                                <Link to={ROUTES.reportList.path} className="btn btn-secondary ml-2">{`Go to ${ROUTES.reportList.displayName}`}</Link>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 py-5">
                            <div className="product-container w-100">
                                <div style={{backgroundImage: `url("${project.view_template.styles.image_url}")`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}} className="product-image-container">
                                    {/*<img alt="original product image" className="img img-fluid product-image" src={project.styles.image_url} />*/}
                                </div>
                                <p>Original Product Image</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExecutiveSummary;
