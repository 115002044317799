import React, {Component} from 'react';
import Modal from 'react-bootstrap4-modal';
import Select from "react-select";

class CreatePdfReportModal extends Component {

    handlePdfReportCreate = () => {
        this.props.doCreatePdfReport(this.props.nameValue, this.props.selectedList)
        this.props.setModalStatus(false)
    }

    render() {
        return (
          <Modal className="dk-modal" visible={this.props.visible} onClickBackdrop={() => this.props.setModalStatus(false)}>
              <div className="modal-header">
                  <h5 className="modal-title">Create new PDF report</h5>
              </div>
              <div className="modal-body">
                  <div className="input-group input-group-sm mb-2">
                      <div className="input-group-prepend ">
                          <span className="input-group-text" id="">Name</span>
                      </div>
                      <input onChange={(e) => this.props.handleOnChangeReportName(e.target.value)} value={this.props.nameValue} type="text" className="form-control form-control-user"/>
                  </div>
                  <Select
                    placeholder="Choose list"
                    options={this.props.userLists}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    onChange={(selectedOption) => this.props.onListSelect(selectedOption)}
                  />
              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.setModalStatus(false)}>
                      Cancel
                  </button>
                  <button type="button" className="btn btn-primary btn-sm" onClick={() => {this.handlePdfReportCreate()}}>
                      Create
                  </button>
              </div>
          </Modal>
        )
    }
}

export default CreatePdfReportModal