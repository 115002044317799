import React from 'react';
import {Link} from "react-router-dom";

class ListLabel extends React.Component {

    render() {
        return (
            <div className="py-1 px-2" style={{backgroundColor: `#${this.props.row.label_color}`}}>
                <span style={{color: `#${this.props.row.text_color}`}} to={`/labels/${this.props.row.id}`}>{this.props.row.name}</span>
            </div>
        )
    }
}

export default ListLabel