import React, {Component} from 'react';
import Sidebar from "../parts/layout/Sidebar"
import Topbar from "../parts/layout/Topbar"
import {connect} from 'react-redux';
import {doCreatePdfReport, downloadPdfReport, fetchPdfReportsList} from '../../actions/pdfReports';
import memoize from 'memoize-one';
import DataTable from 'react-data-table-component';
import Header from '../parts/pdfReports/Header';
import ProcessStatus from "../parts/pdfReports/ProcessStatus";
import {Link} from "react-router-dom";
import moment from "moment";
import CreatePdfReportModal from "../parts/pdfReports/CreatePdfReportModal";
import {fetchUserListData} from "../../actions/lists";

const columns = memoize((downloadPdfReport) => [
    {
        name: 'Name',
        selector: 'title',
        cell: row => row.title,
        maxWidth: '300px',
    },
    {
        name: 'From list',
        selector: 'title',
        cell: row => <Link to={`/list/${row.dupe_list.id}`}>{row.dupe_list.title}</Link>,
        maxWidth: '300px',
    },
    {
        name: 'Created at',
        selector: 'title',
        cell: row => moment(row.created).format('DD/MM/YYYY'),
        maxWidth: '300px',
    },
    {
        name: 'Status',
        selector: 'title',
        cell: row => <ProcessStatus row={row}/>,
    },

    {
        name: 'Download',
        selector: 'title',
        cell: row => <button onClick={() => downloadPdfReport(row.id)} className="btn btn-primary btn-sm" disabled={row.url === null}><i className="fa fa-download" aria-hidden="true"></i></button>,
        maxWidth: '50px',
        center: true
    }
]);

class PdfReports extends Component {

    constructor(props) {
        super(props)

        this.state = {
            filteredText: '',
            visible: false,
            reportName: '',
            selectedList: ''
        }
    }

    componentDidMount() {
        this.props.fetchPdfReportsList()
        this.props.fetchUserListData()
        this.interval = setInterval(() => {this.props.fetchPdfReportsList()}, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setModalStatus = (status) => {this.setState({visible: status})}

    render() {

        const filteredItems = this.props.pdfReports.list.filter(item => item.title && item.title.includes(this.state.filteredText));

        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar />
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content">
                          <Topbar pageTitle={this.props.pageTitle} />
                          <div className="container-fluid no-subheader">
                              <div className="pdf-reports-table">
                                  <DataTable
                                    columns={columns(this.props.downloadPdfReport)}
                                    data={filteredItems}
                                    keyField={'id'}
                                    pagination
                                    paginationPerPage={30}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 75, 100]}
                                    defaultSortField={'created'}
                                    defaultSortAsc={false}
                                    noHeader
                                    subHeader
                                    subHeaderComponent={
                                        <Header
                                          value={this.state.filteredText}
                                          handleOnFilterTextChange={(filteredText) => this.setState({filteredText})}
                                          setModalStatus={this.setModalStatus}
                                        />}
                                  />
                              </div>
                          </div>
                          <CreatePdfReportModal
                            visible={this.state.visible}
                            setModalStatus={this.setModalStatus}
                            handleOnChangeReportName={(nameValue) => this.setState({reportName: nameValue})}
                            nameValue={this.state.reportName}
                            userLists={this.props.userLists.userLists}
                            doCreatePdfReport={this.props.doCreatePdfReport}
                            onListSelect={(selectedOption) => this.setState({selectedList: selectedOption.id})}
                            selectedList={this.state.selectedList}
                          />
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pdfReports: state.pdfReports,
        userLists: state.userLists
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        fetchPdfReportsList: () => {
            dispatch(fetchPdfReportsList())
        },
        fetchUserListData: () => {
            dispatch(fetchUserListData())
        },
        doCreatePdfReport: (reportName, listId) => {
            dispatch(doCreatePdfReport(reportName, listId))
        },
        downloadPdfReport: (reportId) => {
            dispatch(downloadPdfReport(reportId))
        }
    }
}

export default connect(mapStateToProps, mapActionsToProps)(PdfReports)