import React, {Component} from 'react';

class SvsFeatures extends Component {

    constructor(props) {
        super(props)

        this.state = {
            features: this.props.imageData.features
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                features: this.props.imageData.features
            })
        }
    }

    // handleBoxChange = (e, featureId) => {
    //     this.props.fetchUpdatedFeatures(e, featureId, this.props.imageData.search_hash)
    // }

    render() {
        let { features } = this.props.imageData;

        return (
          <div className="svs-features mt-3">
                <div className="row">
                    <div className="col-3">
                        <div className="row">
                            <div className="col-12 feature-category">
                                <div>Skyline</div>
                            </div>
                        </div>
                      {features.map((feature) => {
                          if (feature.short_code === "QUAD" || feature.short_code === "DMS") {
                              return (
                                <div key={feature.short_code} className="row">
                                    <div className="col-12 feature-col">
                                        <div className="custom-control custom-checkbox small">
                                            <input type="checkbox" checked={feature.has_feature} readOnly={true} className="mr-2 custom-control-input" id={feature.id} />
                                            <label className="custom-control-label" htmlFor={feature.id}>{feature.short_code}</label>
                                        </div>
                                    </div>
                                </div>
                              )
                          }
                          return null
                      })}
                    </div>
                    <div className="col-3">
                        <div className="row">
                            <div className="col-12 feature-category">
                                <div>Other</div>
                            </div>
                        </div>
                        {features.map((feature) => {
                            if (feature.short_code === "YWS" || feature.short_code === "HL") {
                                return (
                                  <div key={feature.short_code} className="row">
                                      <div className="col-12 feature-col">
                                          <div className="custom-control custom-checkbox small">
                                              <input type="checkbox" readOnly={true} checked={feature.has_feature} className="mr-2 custom-control-input" id={feature.id} />
                                              <label className="custom-control-label" htmlFor={feature.id}>{feature.short_code}</label>
                                          </div>
                                      </div>
                                  </div>
                                )
                            }
                            return null
                        })}
                    </div>
                </div>
          </div>
        )
    }
}

export default SvsFeatures;