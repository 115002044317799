import React from "react";

export const CookieInfo = (props) => {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center py-2">
                <p className="mb-0 pl-3">{props.name}</p>
                <i data-toggle="collapse" data-target={`#${props.name}`} className="fas fa-chevron-down"></i>
            </div>

            <div id={props.name} className="collapse pl-3" aria-labelledby={props.name}
                 data-parent="#cookie-accordion">
                <table className="table table-striped cookies-table">
                    <tbody>
                        <tr>
                            <th scope="row">Host</th>
                            <td>{props.host}</td>
                        </tr>
                        <tr>
                            <th scope="row">Duration</th>
                            <td>{props.duration}</td>
                        </tr>
                        <tr>
                            <th scope="row">Type</th>
                            <td>{props.type}</td>
                        </tr>
                        <tr>
                            <th scope="row">Category</th>
                            <td>{props.category}</td>
                        </tr>
                        <tr>
                            <th scope="row">Description</th>
                            <td>{props.description}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr/>
        </>
    )
}
