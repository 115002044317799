import React, { Component, Fragment } from 'react';
import {Link} from "react-router-dom";
import Label from "../labels/Label";
import {ROUTES} from "../../../routes/frontend";

class Image extends Component {

    handleInfoButtonClick = (e) => {
        e.stopPropagation()
        this.props.togglePanel(this.props.image)
    }

    handleAddLabel = (label) => {
        if (this.props.image.labels.filter(imageLabel => imageLabel.id === label.id).length > 0) return
        this.props.addLabelToSearchResult(label, this.props.image.id)
    }

    handleRemoveLabel = (label) => {
        this.props.removeLabelFromSearchResult(label, this.props.image.id)
    }

    render() {

        const arrayUniqueByKey = [...new Map(this.props.image.labels.map(label =>
            [label['id'], label])).values()];

        return (
            <Fragment>
                <div className={`col-sm-6 col-md-4 col-lg-4 col-xl-3 image-col mb-4 ${ + this.props.isSelected ? 'selected' : ''}`}>
                    <div className="card img-card h-100">
                        <div onClick={() => this.props.handleImageSelect(this.props.image)} className="image-wrapper" style ={ { backgroundImage: "url("+this.props.image.image_url+")" } }>


                            {/*<label className="custom-control custom-checkbox img-selected-checkbox">*/}
                            {/*    <input checked={this.props.isSelected} type="checkbox" className="custom-control-input" />*/}
                            {/*    <span className="custom-control-indicator"></span>*/}
                            {/*</label>*/}


                            <input className="img-selected-checkbox" checked={this.props.isSelected} onChange={() => null} type="checkbox" name={this.props.image.id}/>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-12">
                                        <small className="img-id">ID: {this.props.image.id}</small>
                                        <br />
                                        <small>
                                            <a target="_blank" rel="noopener" href={this.props.image.image_source}>{this.props.image.image_source.slice(0, 30)}</a>
                                        </small>
                                        {/*<small>Price: {this.props.image.currency_and_price || "N/A"}</small>*/}
                                    </div>
                                    <div className="col-4 text-right">

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        {this.props.hasInfoPanel &&
                                            <button onClick={(e) => this.handleInfoButtonClick(e, this.props.image)}
                                                    className="btn btn-primary btn-sm image-info-btn" type="button">Full details</button>
                                        }
                                    </div>
                                    <div className="col text-right">
                                        <div className="dropdown">
                                            <button className="btn btn-sm btn-outline-secondary" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Add a tag</button>
                                            <div className="dropdown-menu">
                                                  <span className="dropdown-item small" href="#" role="button">
                                                      <Link to={ROUTES.tags.path} className="px-3 py-1 mx-1 d-inline-block">Create new tag</Link>
                                                  </span>
                                                <div className="dropdown-divider"></div>
                                                {this.props.existingLabels.map(label => {
                                                    return (
                                                        <span key={label.id} className="dropdown-item small " href="#" role="button" onClick={(e) => this.handleAddLabel(label)}>
                                                          <span className="px-3 py-1 mx-1 d-inline-block w-100" style={{backgroundColor: `#${label.label_color}`, color: `#${label.text_color}`}}>{label.name}</span>
                                                      </span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.props.image.labels.length > 0 &&
                                            <div className="labels-list py-2">
                                                {arrayUniqueByKey.map(label => {
                                                    return (
                                                        <Label key={label.id} label={label} handleRemove={this.handleRemoveLabel} />
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </Fragment>
        );
    }
}

export default Image;
