import React from 'react';

class Actions extends React.Component  {

    handleEdit  = () => {
        this.props.setUpdateModalStatus(true)
        this.props.setSelectedRow(this.props.row)
    }

    handleDelete = () => {
        this.props.setModalStatus(true)
        this.props.setSelectedRow(this.props.row)
    }

    render() {
        return (
            <div className="actions-container actions-labels">
                <button title="Edit label" className="btn btn-outline-primary btn-sm mx-1" onClick={this.handleEdit}>
                    <i className="far fa-edit"></i>
                </button>
                <button title="Delete label" className="btn btn-outline-primary btn-sm mx-1" onClick={this.handleDelete}>
                    <i style={{color: 'red'}} className="fas fa-times"></i>
                </button>
            </div>
        )
    }
};

export default Actions;