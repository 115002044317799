import * as projectActions from "../actions/projects";

const initialState = {
    projectList: [],
    fetching: false
};

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case projectActions.SET_FETCHING_PROJECT_LIST:
            return {
                ...state,
                fetching: action.payload.status
            }
        case projectActions.SET_PROJECT_LIST:
            return {
              ...state,
                projectList: action.payload.list
            }
        default:
            return state
    }
}
