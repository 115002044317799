import React, {useState} from 'react';
import ActionDropdown from "./ActionDropdown";
import {Button} from "reactstrap";
import {downloadCsv} from "../../../actions/csvReports";

const Actions = (props) => {

    const [showSpinner, setShowSpinner] = useState(false);

    const handleCsvDownload = async () => {
        setShowSpinner(true)
        await downloadCsv(props.row.id)
        setShowSpinner(false)
    }

    return (
      <div className="actions-container d-flex justify-content-between">
          <Button
              size="sm"
              color="primary"
              title="Generate CSV"
              onClick={() => handleCsvDownload()}
              disabled={!props.row.item_count > 0}
              className="mr-2"
          >
              {showSpinner === true ? <i className="fas fa-circle-notch fa-spin text-white"></i> : 'CSV'}
          </Button>
          <ActionDropdown
            row={props.row}
            label="PDF"
          />
      </div>
    )
};

export default Actions;