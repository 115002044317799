import {
    SET_IDS_LIST,
    SET_FETCHING_IDS,
    SET_IDS_FOR_NAVIGATION,
    SET_INDEX_IDS_FOR_NAVIGATION,
    SET_SINGLE_VIEW_IMAGE_DATA,
    SET_FETCHING_SINGLE_VIEW_IMAGE_DATA,
} from "../actions/reportsList";
import {SET_BOOKMARK} from "../actions/bookmarks";
import {SET_NOTES} from "../actions/notes";
import {SET_REVIEWED} from "../actions/reviewed";
import * as labelActions from "../actions/labels"

// import {UPDATE_SEARCH_RESULT_FEATURES} from "../actions/shortlist/shortlist";
const UPDATE_SEARCH_RESULT_FEATURES = "update";

const initialState = {
    ids: [],
    imageData: null,
    fetching: false,
    fetchingIds: false,
    currentIdIndex: null,
    nextIdIndex: null,
    previousIdIndex: null,
    currentId: null,
    nextId: null,
    prevId: null,
    notes: ''
};

export default function reportListReducer(state = initialState, action) {

    switch (action.type) {

        case SET_FETCHING_SINGLE_VIEW_IMAGE_DATA:
            return {
                ...state,
                fetching: action.payload.status
            }
        case SET_IDS_LIST:
            return {
                ...state,
                ids: action.payload.idsList
            }
        case SET_FETCHING_IDS:
            return {
                ...state,
                fetchingIds: action.payload.status
            }
        case SET_SINGLE_VIEW_IMAGE_DATA:
            return {
                ...state,
                imageData: action.payload.imageData
            }
        case SET_INDEX_IDS_FOR_NAVIGATION:
            return {
                ...state,
                currentIdIndex: action.payload.currentIdIndex,
                nextIdIndex: action.payload.nextIdIndex,
                previousIdIndex: action.payload.prevIdIndex
            }
        case SET_IDS_FOR_NAVIGATION:
            return {
                ...state,
                currentId: action.payload.currentId,
                nextId: action.payload.nextId,
                prevId: action.payload.prevId
            }
        case UPDATE_SEARCH_RESULT_FEATURES:

            let copyOfImageData = {...state.imageData}

            copyOfImageData.features = action.payload.features

            return {
                ...state,
                imageData: copyOfImageData
            }
        case SET_BOOKMARK:

            let cpImageData = {...state.imageData}

            cpImageData.bookmarked = action.payload.status

            return {
                ...state,
                imageData: cpImageData
            }
        case SET_REVIEWED:

            let cpImageDataRev = {...state.imageData}
            cpImageDataRev.reviewed_by_client = action.payload.status

            return {
                ...state,
                imageData: cpImageDataRev
            }

        // Notes
        case SET_NOTES:
            return {
                ...state,
                notes: action.payload.notes
            }

        // Labels
        case labelActions.REMOVE_LABEL_SEARCH_RESULT:

            if (null === state.imageData) {
                return {
                    ...state
                }
            }

            let lbCopyOfImageData = Object.assign({}, state.imageData)


            let indexOfLabelToBeRemoved = lbCopyOfImageData.labels.findIndex(label => label.id === action.payload.label.id)
            lbCopyOfImageData.labels.splice(indexOfLabelToBeRemoved, 1)

            return {
                ...state,
                imageData: lbCopyOfImageData
            }

        case labelActions.LABEL_SEARCH_RESULT:

            // Need to check if imageData is not null otherwise will get an error when calling .labels on it
            if (null === state.imageData) {
                return {
                    ...state
                }
            }

            let lbAddCopyOfImageData = Object.assign({}, state.imageData)

            lbAddCopyOfImageData.labels = [...lbAddCopyOfImageData.labels, action.payload.label]

            return {
                ...state,
                imageData: lbAddCopyOfImageData
            }

        default:
            return state
    }
}