import AuthAgent from '../libs/AuthAgent';
import {Cookies} from "react-cookie-consent";
import {COOKIE_DURATION, COOKIE_NAME} from "../utils/cookies/cookieConstants";
import {ROUTES} from "../routes/frontend";
import axios from "axios";

export const SET_FETCHING_AUTH = 'auth:setFetching';
export const SET_ERROR_MESSAGE_AND_STATUS = 'auth:setErrorMessage';
export const LOGOUT = 'auth:logout';

export const setFetching = status => {
    return {
        type: SET_FETCHING_AUTH,
        payload: {
            status
        }
    }
}

export const setErrorMessageAndStatus = (status, message) => {
    return {
        type: SET_ERROR_MESSAGE_AND_STATUS,
        payload: {
            status,
            message
        }
    }
}


export const makeLogout = () => {
    return {
        type: LOGOUT,
        payload: {}
    }
}

export const loggingOut = history => {
    return dispatch => {
        dispatch(makeLogout())
        history.push(ROUTES.login.path)
    }
}

export const fetchAuthDataSso = (idToken, history) => {
    return dispatch => {

        let payload = {
            idToken
        }

        dispatch(setFetching(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/authenticate",
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            // AuthAgent.setCookie('token', response.data.data.token)
            localStorage.setItem('user_handle', response.data.data.email)

            let consentValue = response.data.data.user.analytics_and_performance_cookies

            if (consentValue === null) {
                // Reset cookie based on what is stored in the backend
                if (typeof Cookies.get(COOKIE_NAME) !== "undefined") {
                    Cookies.remove(COOKIE_NAME)
                }
                history.push(ROUTES.projectList.path)
            } else {
                // This means we already have a previous cookie so do nothing except redirect to projectList
                if (typeof Cookies.get(COOKIE_NAME) !== "undefined") {
                    Cookies.set(COOKIE_NAME, consentValue)
                    history.push(ROUTES.projectList.path)
                } else {
                    Cookies.set(COOKIE_NAME, consentValue, {expires: COOKIE_DURATION, domain: process.env.HOST})
                }
            }

            history.push(ROUTES.projectList.path)
            dispatch(setFetching(false));
        }).catch((error) => {
            dispatch(setFetching(false));
            let message;
            switch (error.response.status) {
                case 404:
                    message = 'Invalid credentials';
                    dispatch(setErrorMessageAndStatus(true, message));
                    break
                case 400:
                    message = 'Email and password fields must not be empty and be valid';
                    dispatch(setErrorMessageAndStatus(true, message));
                    break;
                default:
                    message = 'Something went wrong';
                    dispatch(setErrorMessageAndStatus(true, message));
            }
        })
    }
};

export const fetchAuthData = (email, password, history) => {
    return dispatch => {

        let payload = {
            email,
            password
        }

        dispatch(setFetching(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/login",
            method: 'POST',
            withCredentials: true,
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            data: payload
        }).then(response => {
            // AuthAgent.setCookie('token', responseData.data.token)
            localStorage.setItem('user_handle', response.data.data.email)

            let consentValue = response.data.data.user.analytics_and_performance_cookies

            if (consentValue === null) {
                // Reset cookie based on what is stored in the backend
                if (typeof Cookies.get(COOKIE_NAME) !== "undefined") {
                    Cookies.remove(COOKIE_NAME)
                }
                history.push(ROUTES.projectList.path)
            } else {
                // This means we already have a previous cookie
                if (typeof Cookies.get(COOKIE_NAME) !== "undefined") {
                    Cookies.set(COOKIE_NAME, consentValue)
                    history.push(ROUTES.projectList.path)
                } else {
                    Cookies.set(COOKIE_NAME, consentValue, {expires: COOKIE_DURATION, domain: process.env.HOST})
                }
            }

            history.push(ROUTES.projectList.path)
            dispatch(setFetching(false));

        }).catch((error) => {
            dispatch(setFetching(false));
            let message;
            switch (error.response.status) {
                case 404:
                    message = 'Invalid credentials';
                    dispatch(setErrorMessageAndStatus(true, message));
                    break
                case 400:
                    message = 'Email and password fields must not be empty and be valid';
                    dispatch(setErrorMessageAndStatus(true, message));
                    break;
                default:
                    message = 'Something went wrong';
                    dispatch(setErrorMessageAndStatus(true, message));
            }
        })
    }
};