import React, {Component, Fragment} from 'react';
import Topbar from "../parts/layout/Topbar";
import Sidebar from "../parts/layout/Sidebar";
import {connect} from 'react-redux';
import {
    addToList,
    fetchListViewDataIds, fetchListViewSingleImageData, fetchUserListData, removeFromList,
    setIndexIdsForNavigation
} from "../../actions/lists";
import SingleSearchResultCardViewer from "../parts/reportsList/SingleSearchResultCardViewer";
import {doBookmark} from "../../actions/bookmarks";
import {saveNotes, setNotes} from "../../actions/notes";
import {doReview} from "../../actions/reviewed";
import {addLabelToSearchResult, fetchLabels, removeLabelFromSearchResult} from "../../actions/labels";

class ListView extends Component {

    componentDidMount() {
        this.props.fetchUserListData()
        this.props.fetchListViewDataIds(this.props.match.params.id)
        this.props.fetchLabels()
    }

    cycleNext = (e, currentIndex) => {

        e.preventDefault()

        let toBeCurrentIndex = currentIndex + 1;
        let toBeNextIndex = toBeCurrentIndex + 1;
        let toBePrevIndex = toBeCurrentIndex - 1;

        if (toBeCurrentIndex > this.props.userLists.userListIds.length - 1) {
            toBeCurrentIndex = currentIndex;
        }

        this.props.setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex);
        this.props.fetchListViewSingleImageData(this.props.userLists.userListIds[toBeCurrentIndex])
    }

    cyclePrev = (e, currentIndex) => {

        e.preventDefault()

        let toBeCurrentIndex = currentIndex - 1;
        let toBeNextIndex = toBeCurrentIndex + 1;
        let toBePrevIndex = toBeCurrentIndex - 1;

        if (toBePrevIndex < 0) {
            toBePrevIndex = null;
        }

        this.props.setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex);
        this.props.fetchListViewSingleImageData(this.props.userLists.userListIds[toBeCurrentIndex])
    }

    render() {
        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar/>
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="user-list svs">
                          <Topbar pageTitle={this.props.pageTitle} />
                          <div className="container-fluid">
                              <div className="row">
                                  <div className="col-12">
                                      <div className="row">
                                          <div className="col d-flex justify-content-center mb-3">
                                              <button
                                                disabled={this.props.userLists.previousIdIndex === null ? true : false}
                                                className="btn btn-primary mr-2"
                                                onClick={(e) => this.cyclePrev(e, this.props.userLists.currentIdIndex)}>
                                                  Prev
                                              </button>
                                              <button
                                                disabled={this.props.userLists.currentIdIndex === this.props.userLists.userListIds.length - 1 ? true : false}
                                                className="btn btn-primary ml-2"
                                                onClick={(e) => this.cycleNext(e, this.props.userLists.currentIdIndex)}>
                                                  Next
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                                  {this.props.userLists.fetchingIds ?
                                    <div className="row h-100">
                                        <div className="col-12 h-100 d-flex align-items-center justify-content-center">
                                            <i className="fas fa-circle-notch fa-spin"></i>
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                        {this.props.userLists.userListIds.length > 0 ?
                                              <div className="col-12">
                                                  {this.props.userLists.imageData &&
                                                  <SingleSearchResultCardViewer
                                                    imageData={this.props.userLists.imageData}
                                                    fetching={this.props.userLists.fetching}
                                                    fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                                    currentIndex={this.props.userLists.currentIdIndex}
                                                    listLength={this.props.userLists.userListIds.length}
                                                    doBookmark={this.props.doBookmark}
                                                    setNotes={this.props.setNotes}
                                                    saveNotes={this.props.saveNotes}
                                                    notesValue={this.props.userLists.imageData.notes}
                                                    doReview={this.props.doReview}
                                                    userLists={this.props.userLists.userLists}
                                                    currentList={this.props.match.params.id}
                                                    hasListOptions={true}
                                                    addToList={this.props.addToList}
                                                    hasRemoveFromList={true}
                                                    handleRemoveFromList={this.props.removeFromList}
                                                    removeLabelFromSearchResult={this.props.removeLabelFromSearchResult}
                                                    addLabelToSearchResult={this.props.addLabelToSearchResult}
                                                    existingLabels={this.props.labels.list}
                                                  />
                                                  }
                                              </div>
                                          :
                                              <div className="col-6 offset-3 text-center">
                                                  <div className="alert alert-info"> The list is empty. Add some search results to it.</div>
                                              </div>
                                        }
                                    </Fragment>
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        projectList: state.projectList,
        userLists: state.userLists,
        labels: state.labels
    }
};

const mapActionToProps = (dispatch) => {
    return {
        fetchListViewDataIds: (listId) => {
            dispatch(fetchListViewDataIds(listId))
        },
        setIndexIdsForNavigation: (toBeCurrentIndex, toBeNextIndex, toBePrevIndex) => {
            dispatch(setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex))
        },
        fetchListViewSingleImageData: (searchResultId) => {
            dispatch(fetchListViewSingleImageData(searchResultId))
        },
        doBookmark: (searchResultsId, bookmarkStatus) => {
            dispatch(doBookmark(searchResultsId, bookmarkStatus))
        },
        // Notes
        setNotes: (notes) => {
            dispatch(setNotes(notes))
        },
        saveNotes: (searchResultId, notes) => {
            dispatch(saveNotes(searchResultId, notes))
        },
        doReview: (searchResultId, reviewStatus) => {
            dispatch(doReview(searchResultId, reviewStatus))
        },
        fetchUserListData: () => {
            dispatch(fetchUserListData())
        },
        addToList: (searchResultId, listId) => {
            dispatch(addToList(searchResultId, listId))
        },
        removeFromList: (searchResultId, listId) => {
            dispatch(removeFromList(searchResultId, listId))
        },
        removeLabelFromSearchResult: (label, searchResultId) => {
            dispatch(removeLabelFromSearchResult(label, searchResultId))
        },
        addLabelToSearchResult: (label, searchResultId) => {
            dispatch(addLabelToSearchResult(label, searchResultId))
        },
        fetchLabels: () => {
            dispatch(fetchLabels())
        }
    }
};

export default connect(mapStateToProps, mapActionToProps)(ListView);