import React, {Fragment} from 'react';

const Header = (props) => {
    return (
      <Fragment>
          <div className="col-auto">
              <button onClick={() => props.setModalStatus(true)}
                      className="btn btn-primary btn-icon-split btn-sm">
                                              <span className="icon">
                                                <i className="fas fa-plus"></i>
                                              </span>
                  <span className="text">New PDF report</span>
              </button>
          </div>
          <div className="col-4">
              <div className="input-group input-group-sm">
                  <div className="input-group-prepend ">
                      <span className="input-group-text" id="">Find</span>
                  </div>
                  <input onChange={e => props.handleOnFilterTextChange(e.target.value)} value={props.value}
                         type="text" className="form-control form-control-user"/>
              </div>
          </div>
      </Fragment>
    )
};

export default Header;