import AuthAgent from '../libs/AuthAgent';
import {toast} from "react-toastify";
import axios from "axios";

export const SET_LISTS_FETCHING = 'userLists:setFetching';
export const SET_FETCHING_IDS = "userLists:setFetchingIds";
export const SET_USER_LISTS = 'userLists:set';
export const SET_SELECTED_LIST = 'userLists:setSelectedList';
export const LIST_CREATED = 'userLists:created';
export const SET_LISTVIEW_IDS = 'userLists:setListView';
export const SET_INDEX_IDS_FOR_NAVIGATION = "userLists:setIndexIdsForNavigation";
export const SET_IDS_FOR_NAVIGATION = "userLists:setIdsForNavigation";
export const SET_SINGLE_VIEW_IMAGE_DATA = "userLists:setImageData";
export const REMOVE_SR_ID_FROM_IDS_LIST = "userLists:removeSrId";

export const setFetchingStatus = (status) => {
    return {
        type: SET_LISTS_FETCHING,
        payload: {
            status
        }
    }
}

export const setUserLists = (lists) => {
    return {
        type: SET_USER_LISTS,
        payload: {
            lists
        }
    }
}

export const setSelectedList = (selectedList) => {
    return {
        type: SET_SELECTED_LIST,
        payload: {
            selectedList
        }
    }
}

export const setNewList = (list) => {
    return {
        type: LIST_CREATED,
        payload: {
            list
        }
    }
}

export const setListViewIds = (listViewIds) => {
    return {
        type: SET_LISTVIEW_IDS,
        payload: {
            listViewIds
        }
    }
}

export const removeSrIdFromList = (id) => {
    return {
        type: REMOVE_SR_ID_FROM_IDS_LIST,
        payload: {
            id
        }
    }
}

export function setIndexIdsForNavigation(currentIdIndex, nextIdIndex, prevIdIndex) {
    return {
        type: SET_INDEX_IDS_FOR_NAVIGATION,
        payload: {
            currentIdIndex,
            nextIdIndex,
            prevIdIndex
        }
    }
}

export function setIdsForNavigation(currentId, nextId, prevId) {
    return {
        type: SET_IDS_FOR_NAVIGATION,
        payload: {
            currentId,
            nextId,
            prevId
        }
    }
}

export function setSingleViewImageData(imageData) {
    return {
        type: SET_SINGLE_VIEW_IMAGE_DATA,
        payload: {
            imageData
        }
    }
}

export function setFetchingIds(status) {
    return {
        type: SET_FETCHING_IDS,
        payload: {
            status
        }
    }
}

export const doCreateList = (listName, listDesc) => {
    return dispatch => {

        let payload = {
            title: listName,
            description: listDesc,
            project_id: JSON.parse(localStorage.getItem('project')).id,
            is_user_defined_list: true
        }

        axios({
            url: `${process.env.REACT_APP_API_BASE_URL}/list`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            console.log(response)
            dispatch(setNewList(response.data))
            dispatch(setSelectedList({label: response.data.title, value: response.data.id}))
            dispatch(fetchUserListData())
            toast('List created')
        }).catch((error) => {
            toast('Something went wrong')
        })
    }
}

export const addToList = (searchResultId, listId) => {
    return dispatch => {

        let payload = {
            search_result_id: searchResultId,
            dupe_list_id: listId,
            project_id: JSON.parse(localStorage.getItem('project')).id
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/list/add`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            dispatch(setNewList(response.data))
            toast('Added to list')
        }).catch((error) => {
            toast('Something went wrong')
        })
    }
}

export const addManyToList = (searchResults, listId) => {

    return dispatch => {

        let payload = {
            search_results: searchResults.map(sr => sr.id),
            dupe_list_id: listId,
            project_id: JSON.parse(localStorage.getItem('project')).id
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/list/add/many`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            dispatch(setNewList(response.data))
            toast('Added to list')
        }).catch((error) => {
            toast('Something went wrong')
        })
    }
}

export const removeFromList = (searchResultId, listId) => {
    return dispatch => {

        let payload = {
            search_result_id: searchResultId,
            dupe_list_id: listId,
            project_id: JSON.parse(localStorage.getItem('project')).id
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/list/remove`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {

            let idsList = response.data.search_results

            dispatch(setListViewIds(idsList))

            let currentIndex = 0
            let currentId = idsList[currentIndex]

            let nextIndex = currentIndex === idsList.length -1 ? null : currentIndex + 1
            let nextId = idsList[nextIndex]

            let prevIndex = null
            let prevId = null

            dispatch(setIndexIdsForNavigation(currentIndex, nextIndex, prevIndex))
            dispatch(setIdsForNavigation(currentId, nextId, prevId))

            if(idsList.length > 0) {
                dispatch(fetchListViewSingleImageData(currentId))
            }

            dispatch(setFetchingIds(false))

            toast('Removed from list')
        }).catch((error) => {
            console.log(error.message)
            toast('Something went wrong')
        })
    }
}

export const fetchUserListData = () => {

    return dispatch => {

        dispatch(setFetchingStatus(true));

        let projectId = JSON.parse(localStorage.getItem('project')).id

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/lists/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setUserLists(response.data))
            dispatch(setFetchingStatus(false))
        }).catch(error => {
            dispatch(setFetchingStatus(false))
            console.log(error.message)
        })
    }
}

export const fetchListViewDataIds = (listId) => {
    return dispatch => {

        dispatch(setFetchingIds(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/list/${parseInt(listId)}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {

            let idsList = response.data.search_results

            dispatch(setListViewIds(idsList))

            let currentIndex = 0
            let currentId = idsList[currentIndex]

            let nextIndex = currentIndex === idsList.length -1 ? null : currentIndex + 1
            let nextId = idsList[nextIndex]

            let prevIndex = null
            let prevId = null

            dispatch(setIndexIdsForNavigation(currentIndex, nextIndex, prevIndex))
            dispatch(setIdsForNavigation(currentId, nextId, prevId))

            if(idsList.length > 0) {
                dispatch(fetchListViewSingleImageData(currentId))
            }

            dispatch(setFetchingIds(false))

        }).catch(error => {
            dispatch(setFetchingIds(false))
            console.log(error.message)
        })
    }
}

export function fetchListViewSingleImageData(searchResultId) {
    return dispatch => {

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/${searchResultId}`,
            method: 'GET',
            withCredentials: 'include',
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setSingleViewImageData(response.data))
        }).catch(error => {
            console.log(error.message)
            toast('Something went wrong')
        })
    }
}
