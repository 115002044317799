export const SET_STATUS = 'reportsListMenu:setStatus';
export const SET_REMOTE_STATUS = 'reportListMenu:setRemoteStatus';
export const SET_HOST = 'reportsListMenu:setHost';
export const SET_TEXT_SEARCH = 'reportsListMenu:setTextSearch';
export const SET_NOTES_SEARCH = 'reportsListMenu:setNotesSearch';
export const SET_PROPERTY_TYPE = 'reportsListMenu:setPropertyType';
export const SET_DK_SELLER_RATING = 'reportsListMenu:setDkSellerRating';
export const SET_FEATURE = 'reportsListMenu:setFeature';
export const SET_ACTION_RECOMMENDED = 'reportsListMenu:setActionRecommended';
export const SET_BOOKMARKED_STATUS = 'reportsListMenu:setBookmarkedStatus';
export const SET_START_DATE = 'reportListMenu:setStartDate';
export const SET_END_DATE = 'reportListMenu:setEndDate';
export const SET_REVIEWED_BY_CLIENT_STATUS = 'reportsListMenu:setReviewedByClientStatus';
export const SET_LABEL = 'reportsListMenu:setLabel';
export const RESET_FILTERS = 'reportListMenu:resetFilters';

export const SET_SORT = 'reportsListMenu:setSort';

// ------------------------------- FILTERS ----------------------------------------
export function setStatus(statusValue) {
    return {
        type: SET_STATUS,
        payload: {
            statusValue,
        }
    }
}

export function setRemoteStatus(remoteStatusValue) {
    return {
        type: SET_REMOTE_STATUS,
        payload: {
            remoteStatusValue
        }
    }
}

export function setHost(hostValue) {
    return {
        type: SET_HOST,
        payload: {
            hostValue
        }
    }
}

export function setTextSearch(value) {
    return {
        type: SET_TEXT_SEARCH,
        payload: {
            value
        }
    }
}

export function setNotesSearch(value) {
    return {
        type: SET_NOTES_SEARCH,
        payload: {
            value
        }
    }
}

export function setPropertyType(propertyType) {
    return {
        type: SET_PROPERTY_TYPE,
        payload: {
            propertyType
        }
    }
}

export function setDkSellerRating(dkSellerRating) {
    return {
        type: SET_DK_SELLER_RATING,
        payload: {
            dkSellerRating
        }
    }
}

export function setFeature(feature) {
    return {
        type: SET_FEATURE,
        payload: {
            feature
        }
    }
}

// export function setActionRecommended(actionRecommended) {
//     return {
//         type: SET_ACTION_RECOMMENDED,
//         payload: {
//             actionRecommended
//         }
//     }
// }
//
// export function setBookmarked(bookmarkedStatus) {
//     return {
//         type: SET_BOOKMARKED_STATUS,
//         payload: {
//             bookmarkedStatus
//         }
//     }
// }
//
// export function setReviewedByClient(reviewedByClientStatus) {
//     return {
//         type: SET_REVIEWED_BY_CLIENT_STATUS,
//         payload: {
//             reviewedByClientStatus
//         }
//     }
// }

export function setStartDate(startDate) {
    return {
        type: SET_START_DATE,
        payload: {
            startDate,
        }
    }
}

export function setEndDate(endDate) {
    return {
        type: SET_END_DATE,
        payload: {
            endDate,
        }
    }
}

export function setLabel(label) {
    return {
        type: SET_LABEL,
        payload: {
            label,
        }
    }
}

export function resetFilters(defaultFilterValues) {
    return {
        type: RESET_FILTERS,
        payload: {
            defaultFilterValues
        }
    }
}

// ------------------------------- SORTING ----------------------------------------
export function setSort(sortKey) {
    return {
        type: SET_SORT,
        payload: {
            sortKey
        }

    }
}
