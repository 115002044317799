import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/js/bootstrap";
import "./assets/scss/main.scss";
import App from './components/App';
import reportWebVitals from './reportWebVitals';

// SSO lib
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./utils/sso/authConfig";
import {MsalProvider} from "@azure/msal-react";

// Redux imports
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// Redux reducer imports
import authenticationReducer from './reducers/authentication';
import projectsReducer from "./reducers/project";
import reportsListMenuReducer from "./reducers/reportsListMenuReducer";
import reportsListReducer from "./reducers/reportsListReducer";
import bookmarksReducer from "./reducers/bookmarksReducer";
import {LOGOUT} from "./actions/authentication";
import cleanupOnLogout from "./utils/authentication/cleanupOnLogout";
import AuthAgent from "./libs/AuthAgent";
import listsReducer from "./reducers/lists";
import pdfReports from "./reducers/pdfReports";
import summaryReducer from "./reducers/summary";
import galleryReducer from "./reducers/gallery";
import labelsReducer from "./reducers/labelsReducer";
import reportListSubheader from "./reducers/reportListSubheader";
import gallerySubheader from "./reducers/gallerySubheader";
import axios from "axios";
import {ROUTES} from "./routes/frontend";

const allReducers = combineReducers({
    authentication: authenticationReducer,
    projects: projectsReducer,
    reportsListMenu: reportsListMenuReducer,
    reportsList: reportsListReducer,
    bookmarks: bookmarksReducer,
    userLists: listsReducer,
    pdfReports: pdfReports,
    summary: summaryReducer,
    gallery: galleryReducer,
    labels: labelsReducer,
    reportListSubheader: reportListSubheader,
    gallerySub: gallerySubheader
});

const rootReducer = (state = {}, action) => {

    if (action.type === LOGOUT) {
        cleanupOnLogout()
        AuthAgent.removeLoginCookie()
        state = undefined;
    }

    return allReducers(state, action)
}

const allStoreEnhancers = compose(
  applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const store = createStore(
  rootReducer,
  {},
  allStoreEnhancers
);

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    console.log(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    switch (error.response.status) {
        case 401:
        case 422:
            window.location.replace(`${window.location.protocol.concat('//')}${window.location.host}${ROUTES.login.path}`)
            break;
        default:
            return Promise.reject(error);
    }
    return Promise.reject(error);
});

const msalInstance = new PublicClientApplication(msalConfig);

localStorage.removeItem('token'); // This is temporary, used in clean-up

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <MsalProvider instance={msalInstance}>
            <App/>
          </MsalProvider>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
