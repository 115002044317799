import * as reportListSubheaderActions from '../actions/reportListSubheader';

export const initialState = {
    filtersTogglePanel: false,
    addToListModalToggle: false
};

export default function reportListSubheader(state = initialState, action) {
    switch (action.type) {

        case reportListSubheaderActions.SET_FILTER_PANEL_TOGGLE:
            return {
                ...state,
                filtersTogglePanel: action.payload.status
            }

        case reportListSubheaderActions.SET_ADD_TO_LIST_MODAL_TOGGLE:
            return {
                ...state,
                addToListModalToggle: action.payload.status
            }
        default:
            return state
    }
}
