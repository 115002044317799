import axios from "axios";
import AuthAgent from "../libs/AuthAgent";
import moment from "moment/moment";
import download from "downloadjs";
import {toast} from "react-toastify";

export const downloadCsv = async listId => {
    try {
        let response = await axios({
            url: process.env.REACT_APP_API_BASE_URL + `/list/${listId}/csv`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            responseType: 'blob'
        })

        let fileName = `csv_report_${moment().format('YYYY-MM-DD_H:m')}.csv`;
        download(response.data, fileName, "text/plain");
    } catch (e) {
        console.log(e)
        toast('Something went wrong')
    }
}
