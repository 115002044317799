import React, {Component} from 'react';
import Modal from 'react-bootstrap4-modal';

class CreateListModal extends Component {

    handleListCreate = () => {
        this.props.doCreateList(this.props.nameValue, this.props.descValue)
        this.props.setModalStatus(false)
    }

    render() {
        return (
          <Modal className="dk-modal" visible={this.props.visible} onClickBackdrop={() => this.props.setModalStatus(false)}>
              <div className="modal-header">
                  <h5 className="modal-title">Create new list</h5>
              </div>
              <div className="modal-body">
                  <div className="input-group input-group-sm mb-2">
                      <div className="input-group-prepend">
                          <span className="input-group-text" id="">Name</span>
                      </div>
                      <input onChange={(e) => this.props.handleOnChangeListName(e.target.value)} value={this.props.nameValue} type="text" className="form-control form-control-user"/>
                  </div>
                  <div className="input-group input-group-sm">
                      <div className="input-group-prepend ">
                          <span className="input-group-text" id="">Description</span>
                      </div>
                      <input onChange={(e) => this.props.handleOnChangeListDesc(e.target.value)} value={this.props.descValue} type="text" className="form-control form-control-user"/>
                  </div>
              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.props.setModalStatus(false)}>
                      Cancel
                  </button>
                  <button type="button" className="btn btn-primary btn-sm" onClick={() => {this.handleListCreate()}}>
                      Create
                  </button>
              </div>
          </Modal>
        )
    }
}

export default CreateListModal