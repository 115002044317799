import {toast} from "react-toastify";
import AuthAgent from '../libs/AuthAgent';
import axios from "axios";

export const SET_NOTES = "notes:set";

export const setNotes = (value) => {
    return {
        type: SET_NOTES,
        payload: {
            notes: value
        }
    }
}

export const saveNotes = (searchResultId, noteValue) => {

    return dispatch => {

        let payload = {
            id: searchResultId,
            notes: noteValue
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/notes`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            console.log(response)
            dispatch(setNotes(response.data.notes))
        }).catch(() => {
            toast('Something went wrong')
        })
    }
}


