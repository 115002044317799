import * as userListsActions from '../actions/lists'
import * as labelActions from "../actions/labels"

const initialState = {
    userLists: [],
    selectedList: null,
    fetching: false,
    userListIds: [],
    currentIdIndex: null,
    nextIdIndex: null,
    previousIdIndex: null,
    currentId: null,
    nextId: null,
    prevId: null,
    imageData: null,
    fetchingIds: false,
};

export default function listsReducer(state = initialState, action) {
    switch (action.type) {

        case userListsActions.SET_USER_LISTS:

            return {
              ...state,
              userLists: action.payload.lists
            }

        case userListsActions.SET_SELECTED_LIST:
            return {
                ...state,
                selectedList: action.payload.selectedList
            }

        case userListsActions.LIST_CREATED:

            return {
              ...state,
                userLists: [...state.userLists, action.payload.list]
            }

        case userListsActions.SET_LISTVIEW_IDS:
            return {
              ...state,
              userListIds: action.payload.listViewIds
            }
        case userListsActions.SET_SINGLE_VIEW_IMAGE_DATA:
            return {
                ...state,
                imageData: action.payload.imageData
            }
        case userListsActions.SET_INDEX_IDS_FOR_NAVIGATION:
            return {
                ...state,
                currentIdIndex: action.payload.currentIdIndex,
                nextIdIndex: action.payload.nextIdIndex,
                previousIdIndex: action.payload.prevIdIndex
            }
        case userListsActions.SET_IDS_FOR_NAVIGATION:
            return {
                ...state,
                currentId: action.payload.currentId,
                nextId: action.payload.nextId,
                prevId: action.payload.prevId
            }
        // Labels
        case labelActions.REMOVE_LABEL_SEARCH_RESULT:

            if (null === state.imageData) {
                return {
                    ...state
                }
            }

            let lbCopyOfImageData = Object.assign({}, state.imageData)


            let indexOfLabelToBeRemoved = lbCopyOfImageData.labels.findIndex(label => label.id === action.payload.label.id)
            lbCopyOfImageData.labels.splice(indexOfLabelToBeRemoved, 1)

            return {
                ...state,
                imageData: lbCopyOfImageData
            }

        case labelActions.LABEL_SEARCH_RESULT:

            // Need to check if imageData is not null otherwise will get an error when calling .labels on it
            if (null === state.imageData) {
                return {
                    ...state
                }
            }

            let lbAddCopyOfImageData = Object.assign({}, state.imageData)

            lbAddCopyOfImageData.labels = [...lbAddCopyOfImageData.labels, action.payload.label]

            return {
                ...state,
                imageData: lbAddCopyOfImageData
            }
        default:
            return state
    }
}
