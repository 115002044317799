import React, {Component} from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import debounce from 'lodash/debounce';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class SvsNotes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty()
        };
    }

    componentDidMount() {

        if (this.props.notesData === null) {
            this.setState({
                editorState: EditorState.createEmpty()
            })

            return
        }

        const blocksFromHtml = htmlToDraft(this.props.notesData);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        this.setState({
            editorState: EditorState.createWithContent(contentState)
        })
    }

    saveContent = debounce(content => {
        this.props.saveNotes(this.props.id, content)
    }, 500)

    onChange = editorState => {
        let content = convertToRaw(editorState.getCurrentContent())
        let html = draftToHtml(content)
        this.saveContent(html)
        this.setState({editorState: editorState})
    }

    render() {

        return (
          <div className="row">
              <div className={`col-${this.props.labelCol}`}><p>Notes</p></div>
              <div className={`col-${this.props.valueCol} px-0`}>
                  {/*<input onBlur={(e) => this.props.saveNotes(this.props.id, e.target.value)}*/}
                  {/*       onChange={(e) => this.props.setNotes(e.target.value)}*/}
                  {/*       type="text" className="form-control form-control-user"*/}
                  {/*       defaultValue={this.props.notesData}*/}
                  {/*/>*/}
                  <Editor
                      editorState={this.state.editorState}
                      onEditorStateChange={this.onChange}
                      // handleKeyCommand={this.handleKeyCommand}
                      toolbar={{
                          options: ['blockType', 'inline', 'textAlign']
                      }}
                  />
                  {/*<button className="btn btn-secondary">Save note</button>*/}
              </div>
          </div>
        )
    }
}

export default SvsNotes