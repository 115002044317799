import React, {Component} from "react";
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {loggingOut} from "../../../actions/authentication";
import {capitalizeFirstLetterOfWord} from "../../../utils/various/stringManipulation";
import LogoutButton from "../logout/LogoutButton";
import {ROUTES} from "../../../routes/frontend";

class Topbar extends Component {

    getCurrentProject = () => {
        return JSON.parse(localStorage.getItem('project'))
    }

    getCurrentUser = () => {
        return localStorage.getItem('user_handle')
    }

    handleOnPrivacyClick = (e) => {
        this.props.history.push(ROUTES.manageCookies.path)
    }

    getProjectDisplayNameExceptionPaths = () => {
        return [
            ROUTES.projectList.path,
            ROUTES.manageCookies.path
        ]
    }

    render() {
        return (
          <nav className="navbar navbar-expand navbar-in sidebar-light topbar bg-white static-top">
              {this.getProjectDisplayNameExceptionPaths().includes(ROUTES.projectList.path) && // Don't show project title if path is root
                  <nav className="navbar navbar-light page-title">
                      <span>{this.props.pageTitle}</span>
                  </nav>
              }
              <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars"></i>
              </button>
              {!this.getProjectDisplayNameExceptionPaths().includes(ROUTES.projectList.path) && // Don't show project title if path is root
              <div className="col-10">
                  <span
                    className="nav-item mt-1 mb-0 ml-3 text-primary mr-3">{capitalizeFirstLetterOfWord(this.getCurrentProject().display_name)}</span>
              </div>
              }

              <ul className="navbar-nav ml-auto">

                  <div className="topbar-divider d-none d-sm-block"></div>

                  <li className="nav-item dropdown">
                      <span className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="mr-2 d-none d-lg-inline text-black small">{this.getCurrentUser()}</span>
                          {/*<img className="img-profile rounded-circle" src={userProfilePic} alt="" />*/}
                      </span>
                      <div className="dropdown-menu dropdown-menu-right animated--grow-in"
                           aria-labelledby="userDropdown">
                          <LogoutButton handleLogout={this.props.handleLogout} />
                          <span className="dropdown-item" href="#"
                                role="button" onClick={(e) => this.handleOnPrivacyClick(e)}>
                              <i className="fas fa-cookie fa-sm fa-fw mr-2 text-gray-400"></i>
                              Cookie preferences
                          </span>
                      </div>
                  </li>
              </ul>
          </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
    }
};

const mapActionsToProps = (dispatch, ownProps) => {
    return {
        handleLogout: () => {
            dispatch(loggingOut(ownProps.history))
        },
    }
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionsToProps),
)(Topbar);