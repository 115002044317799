import React, {Component} from "react";
import {formatNumberAsIntl} from "../../../../utils/various/format";
import {ROUTES} from "../../../../routes/frontend";
import {initialState} from "../../../../reducers/reportsListMenuReducer";
import {getStatusFilterOptions} from "../../../../utils/dataSelection/statusFilterOptions";

class TopWebsitesFeaturingDupesByDupesCount extends Component {

    handleCountClick = (e, host) => {
        e.preventDefault()
        this.props.resetFilters(initialState)
        this.props.setRemoteStatus(getStatusFilterOptions()[0])
        this.props.setHost(host)
        this.props.setStartDate(new Date(this.props.summary.from))
        this.props.setEndDate(new Date(this.props.summary.to))
        this.props.router.push(ROUTES.galleryList.path)
    }

    render() {
        return (
          <div className="card">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold">Top websites featuring dupes by dupes count</h6>
              </div>
              <div className="card-body ">
                  <div className="table-responsive table-hover">
                      <table className="table">
                          <thead>
                          <tr>
                              <th scope="col">Rank</th>
                              <th scope="col">Website</th>
                              <th scope="col">Dupe Count</th>
                              <th scope="col">Monthly Visits (Est.)</th>
                          </tr>
                          </thead>

                          <tbody>
                          {this.props.data.slice(0, 10).map((item, index) => {
                              let estimatedMonthlyVisits = item.estimated_monthly_visits === undefined ? '' : formatNumberAsIntl(item.estimated_monthly_visits)
                              estimatedMonthlyVisits = estimatedMonthlyVisits === '0' ? 'Not available' : estimatedMonthlyVisits
                              let dupeCount =  item.dupe_count === undefined ? '' : formatNumberAsIntl(item.dupe_count)
                              dupeCount = dupeCount === '0' ? 'Not available' : dupeCount

                              return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><a target="_blank"  rel="noreferrer" href={'//' + item.domain}>{item.domain}</a></td>
                                    <td><a onClick={(e) => this.handleCountClick(e, item.domain)} href="#">{dupeCount}</a></td>
                                    <td>{estimatedMonthlyVisits}</td>
                                </tr>
                              )
                          })}
                          </tbody>
                      </table>
                  </div>

              </div>
          </div>
        )
    }
}

export default TopWebsitesFeaturingDupesByDupesCount;
