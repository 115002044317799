import React from 'react';
import {Link} from "react-router-dom";
import Sticky from 'react-sticky-el';
import {ROUTES} from "../../../../routes/frontend";

class GallerySub extends React.Component {

    handleAddLabel = (label) => {
        this.props.addLabelToSearchResult([label], this.props.selectedImages.map(image => image.id))
    }

    render() {
        return (
            <div className="sticky-container">
                <Sticky stickyClassName={'sticky'}>
                <div className="subheader d-flex justify-content-end align-items-center">
                    <button onClick={() => this.props.handleSelectAll()} className="btn btn-secondary mr-3">Select all</button>
                    <button disabled={this.props.selectedImages.length === 0} onClick={() => this.props.handleDeselectAll()} className="btn btn-secondary mr-3">Deselect all</button>
                    <div className="dropdown d-inline-block mr-3">
                        <button className="btn btn-secondary" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add a tag</button>
                        <div className="dropdown-menu">
                                  <span className="dropdown-item small" role="button">
                                      <Link to={ROUTES.tags.path} className="px-3 py-1 mx-1 d-inline-block">Create new tag</Link>
                                  </span>
                            {this.props.existingLabels.map(label => {
                                return (
                                    <span key={label.id} className="dropdown-item small" role="button" onClick={(e) => this.handleAddLabel(label)}>
                                      <span className="px-3 py-1 mx-1 d-inline-block w-100" style={{backgroundColor: `#${label.label_color}`, color: `#${label.text_color}`}}>{label.name}</span>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                    <button onClick={() => this.props.setAddToListModalToggle(!this.props.addToListModalToggleValue)} className="btn btn-secondary mr-3">Add to list</button>
                    <button onClick={() => this.props.setFilterPanelToggle(!this.props.filtersPanelToggleValue)} className="btn btn-secondary">Filter & Sort</button>
                </div>
                </Sticky>
            </div>
        )
    }
}

export default GallerySub;
