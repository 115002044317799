import React, {Component} from 'react';
import Modal from 'react-bootstrap4-modal';

class DeleteLabelModal extends Component {

    handleConfirm = () => {
        this.props.doDeleteLabel(this.props.label)
        this.props.setModalStatus(false)
    }

    render() {
        return (
          <Modal className="dk-modal" visible={this.props.visible} onClickBackdrop={() => this.props.setModalStatus(false)}>
              <div className="modal-header">
                  <h5 className="modal-title">Confirm delete label</h5>
              </div>
              <div className="modal-body">
                <span>Are you sure you want to delete the label?</span>
              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.props.setModalStatus(false)}>
                      Cancel
                  </button>
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => {this.handleConfirm()}}>
                      Delete
                  </button>
              </div>
          </Modal>
        )
    }
}

export default DeleteLabelModal