export const SET_CURRENT_PAGE = 'pagination:setCurrentPage';
export const SET_TOTAL_ITEMS = 'pagination:setTotalItems';
export const SET_ITEMS_COUNT_PER_PAGE = 'pagination:setItemsCountPerPage';

export function setCurrentPage(currentPageValue) {
    return {
        type: SET_CURRENT_PAGE,
        payload: {
            currentPageValue
        }
    }
}

export function setTotalItems(totalItems) {
    return {
        type: SET_TOTAL_ITEMS,
        payload: {
            totalItems
        }
    }
}

export function setItemsCountPerPage(itemsCountPerPage) {
    return {
        type: SET_ITEMS_COUNT_PER_PAGE,
        payload: {
            itemsCountPerPage
        }
    }
}
