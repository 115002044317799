import * as pdfReportsActions from '../actions/pdfReports';

const initialState = {
    fetching: false,
    list: []
}

export default function pdfReports(state = initialState, action) {
    switch (action.type) {
        case pdfReportsActions.SET_FETCHING:
            return {
              ...state,
                fetching: action.payload.status
            }
        case pdfReportsActions.SET_PDF_REPORTS_LIST:
            return {
              ...state,
                list: action.payload.listData
            }

        case pdfReportsActions.ADD_PDF_REPORT:
            return {
              ...state,
                list: [action.payload.reportData, ...state.list]
            }

        default:
            return state
    }
}