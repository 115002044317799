import * as galleryAction from "../actions/gallery";
import * as paginationActions from '../actions/pagination'
import * as labelActions from '../actions/labels';

const initialState = {
    list: [],
    fetching: false,
    imageForSidePanel: null,
    isSidePanelOpen: false,

    // Pagination
    currentPageValue: 1,
    totalItems: 0,
    itemsCountPerPage: 40,
};

export default function galleryReducer(state = initialState, action) {
    switch (action.type) {
        case galleryAction.FETCHING:
            return {
                ...state,
                fetching: action.payload.status
            }

        case galleryAction.SET_IMAGES:
            return {
                ...state,
                list: action.payload.images
            }

        case labelActions.LABEL_SEARCH_RESULT:

            return {
                ...state,
                list: state.list.map(searchResult => {
                    if (searchResult.id === action.payload.searchResultId) {
                        searchResult.
                            labels = [...searchResult.labels, action.payload.label]
                    }

                    return searchResult
                })
            }
        case labelActions.REMOVE_LABEL_SEARCH_RESULT:
            // In some cases, the list might be empty, so we do not operate on it
            if (state.list.length === 0) {
                return {
                    ...state
                }
            }

            let copyOfSrList = [...state.list];

            let sr = copyOfSrList.find(sr => sr.id === action.payload.searchResultId)
            let indexOfLabelToBeRemoved = sr.labels.findIndex(label => label.id === action.payload.label.id)

            sr.labels.splice(indexOfLabelToBeRemoved, 1);

            return {
                ...state,
                list: copyOfSrList
            }

        case labelActions.LABEL_MANY_SEARCH_RESULTS:
            // In some cases, the list might be empty, so we do not operate on it
            if (state.list.length === 0) {
                return {
                    ...state
                }
            }

            return {
                ...state,
                list: state.list.map(sr => {
                    action.payload.searchResultIds.forEach(srId => {
                        if (sr.id === srId) {
                            sr.labels.push(action.payload.label)
                        }
                    })
                    return sr
                })
            }

        // Gallery Image side panel
        case galleryAction.SIDEPANEL_STATUS:
            return {
                ...state,
                isSidePanelOpen: action.payload.status
            }

        case galleryAction.SET_IMAGE_FOR_SIDEPANEL:
            return {
                ...state,
                imageForSidePanel: action.payload.imageData
            }

        // Pagination
        case paginationActions.SET_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload.totalItems
            }
        case paginationActions.SET_ITEMS_COUNT_PER_PAGE:
            return {
                ...state,
                itemsCountPerPage: action.payload.itemsCountPerPage
            }
        case paginationActions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPageValue: action.payload.currentPageValue
            }
        default:
            return state
    }
}
