import React, {Component, Fragment} from 'react';
import Sidebar from "../parts/layout/Sidebar";
import Pagination from "react-js-pagination";
import {
    doPrint,
    fetchSingleViewImageData,
    setIndexIdsForNavigation
} from "../../actions/reportsList";
import {doBookmark} from "../../actions/bookmarks";
import {saveNotes, setNotes} from "../../actions/notes";
import {doReview} from "../../actions/reviewed";
import {addManyToList, doCreateList, fetchUserListData, setSelectedList} from "../../actions/lists";
import {connect} from "react-redux";
import Topbar from "../parts/layout/Topbar";
import ReportsListMenu from "../parts/reportsListMenu/ReportsListMenu";
import {
    doCloseAndUnsetImageForGallery,
    doOpenAndSetImageForGallery,
    fetchGallerySearchResults
} from "../../actions/gallery";
import Image from "../parts/gallery/Image";
import {getPaginationItemsCountOptions} from "../../utils/pagination/paginationItemsCountOptions";
import {setCurrentPage, setItemsCountPerPage} from "../../actions/pagination";
import prepareFilters from "../../utils/filters/prepareFilters";
import {
    fetchLabels,
    addLabelToSearchResult,
    removeLabelFromSearchResult, addManyLabelsToManySearchResults,
} from "../../actions/labels";
// import ActionsMenu from "../parts/actionsMenu/ActionsMenu";
import SearchResultInfoPanel from "../parts/gallery/SearchResultInfoPanel"
import {setFilterPanelToggle} from "../../actions/gallerySubheader";
import GallerySub from "../parts/layout/subheader/GallerySub";
import AddToListModal from "../parts/reportsList/AddToListModal";
import {setAddToListModalToggle} from "../../actions/gallerySubheader";
import ScrollLock from "react-scrolllock";
// import {getStatusFilterOptions} from "../../utils/dataSelection/statusFilterOptions";


class GalleryList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedImages: [],
            allSelected: false,
        }
    }

    componentDidMount() {
        let filters = prepareFilters(this.props.menu)
        filters.currentPageValue = 1
        filters.itemsCountPerPage = this.props.gallery.itemsCountPerPage

        this.props.fetchGallerySearchResults(filters, []);
        this.props.fetchLabels();
        this.props.fetchUserListData()
    }

    handlePageChange = (page) => {

        this.setState(prevState => {
            return {
                allSelected: false
            }
        })

        this.props.setCurrentPage(parseInt(page))
        let filters = prepareFilters(this.props.menu)
        filters.itemsCountPerPage = this.props.gallery.itemsCountPerPage
        filters.currentPageValue = parseInt(page)
        this.props.fetchGallerySearchResults(filters, [])
    }

    handleItemsCountPerPage = (perPage) => {
        this.setState(prevState => {
            return {
                selectedImages: [],
                allSelected: false
            }
        })
        this.props.setItemsCountPerPage(parseInt(perPage))
        this.props.setCurrentPage(1)
        let filters = prepareFilters(this.props.menu)
        filters.itemsCountPerPage = parseInt(perPage)
        filters.currentPageValue = this.props.gallery.currentPageValue
        this.props.fetchGallerySearchResults(filters, [])
    }

    handleImageSelect = (currentImage) => {
        let result = this.state.selectedImages.filter(image => {
            return image.id === currentImage.id
        });
        if (result.length === 0 ) {
            this.setState(prevState => {
                return {
                    selectedImages: [...prevState.selectedImages, currentImage]
                }
            })
        } else {
            let index = this.state.selectedImages.findIndex(image => {
                return image.id === currentImage.id
            });

            this.setState(prevState => {
                let copyOfSelectedImages = [...prevState.selectedImages]
                copyOfSelectedImages.splice(index, 1)
                return {
                    selectedImages: copyOfSelectedImages
                }
            })
        }
    }

    handleSelectAll = () => {

        this.props.gallery.list.forEach(galleryImage => {
            let result = this.state.selectedImages.some(selectedImage => galleryImage.id === selectedImage.id)

            if (!result) {
                this.setState(prevState => {
                    return {
                        selectedImages: [...prevState.selectedImages, galleryImage]
                    }
                })
            }
        })
    }

    render() {
        let projectId = JSON.parse(localStorage.getItem('project')).id
        let hasImages = this.props.gallery.list.length > 0

        let images = this.props.gallery.list.map((image, index) => {
            return (
                <Image
                    key={index}
                    image={image}
                    project={projectId}
                    handleImageSelect={this.handleImageSelect}
                    isSelected={this.state.selectedImages.some(selectedImage => image.id === selectedImage.id)}
                    hasScore={false}
                    // selectedPercentMatch={this.props.selectedPercentMatch}
                    // canBeFilteredByPercentMatch={false}
                    hasInfoPanel={true}
                    togglePanel={this.props.doOpenAndSetImageForGallery}
                    fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                    existingLabels={this.props.labels.list}
                    addLabelToSearchResult={this.props.addLabelToSearchResult}
                    removeLabelFromSearchResult={this.props.removeLabelFromSearchResult}
                />
            )
        });

        return (
            <>
                <div id="page-top">
                    <div id="wrapper">
                        <Sidebar/>
                        <div id="content-wrapper" className="d-flex flex-column">
                            <div id="content" className="svs">
                                <Topbar pageTitle={this.props.pageTitle} />
                                <GallerySub
                                    setFilterPanelToggle={this.props.setFilterPanelToggle}
                                    filtersPanelToggleValue={this.props.gallerySub.filtersTogglePanel}
                                    handleSelectAll={this.handleSelectAll}
                                    handleDeselectAll={() => this.setState({selectedImages: []})}
                                    setAddToListModalToggle={this.props.setAddToListModalToggle}
                                    addToListModalToggleValue={this.props.gallerySub.addToListModalToggle}
                                    existingLabels={this.props.labels.list}
                                    addLabelToSearchResult={this.props.addManyLabelsToManySearchResults}
                                    selectedImages={this.state.selectedImages}
                                />
                                <ReportsListMenu
                                    setFilterPanelToggle={this.props.setFilterPanelToggle}
                                    visible={this.props.gallerySub.filtersTogglePanel}
                                    fetchResults={this.props.fetchGallerySearchResults}
                                    setCurrentPage={this.props.setCurrentPage}
                                    pagination={{
                                        currentPageValue: this.props.gallery.currentPageValue,
                                        itemsCountPerPage: this.props.gallery.itemsCountPerPage,
                                    }}
                                />

                                {/*<ActionsMenu*/}
                                {/*    lists={this.props.userLists.userLists}*/}
                                {/*    labels={this.props.labels.list}*/}
                                {/*    addManyToList={this.props.addManyToList}*/}
                                {/*    addManyLabelsToManySearchResults={this.props.addManyLabelsToManySearchResults}*/}
                                {/*    selectedImages={this.state.selectedImages}*/}
                                {/*/>*/}

                                <ScrollLock isActive={this.props.gallery.isSidePanelOpen || this.props.gallerySub.filtersTogglePanel}>
                                    <div className="container-fluid">
                                        {this.props.gallery.fetching ? (
                                            <React.Fragment>
                                                <div className="row d-flex justify-content-center align-items-center h-100">
                                                    <i className="fas fa-circle-notch fa-spin fetching-spinner text-primary"></i>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {hasImages ? (
                                                    <div className="row">
                                                        {images}
                                                    </div>
                                                ) : (
                                                    <div className="row d-flex align-item-center justify-content-center">
                                                        <div className="col-6 col-offset-3 text-center">
                                                            <div className="alert alert-info">No search results matching your filters.
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="pagination-row row mb-3 d-flex justify-content-center align-items-center">

                                                    <Pagination
                                                        activePage={this.props.gallery.currentPageValue}
                                                        itemsCountPerPage={this.props.gallery.itemsCountPerPage}
                                                        totalItemsCount={this.props.gallery.totalItems}
                                                        onChange={(page) => this.handlePageChange(page)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />

                                                    <select onChange={(e) => {
                                                        this.handleItemsCountPerPage(e.target.value)
                                                    }}
                                                            className="form-control form-control-user custom-control custom-select pagination-items-count-select"
                                                            value={this.props.gallery.itemsCountPerPage}>
                                                        {
                                                            getPaginationItemsCountOptions().map((paginationOption) => {
                                                                return <option key={paginationOption.value}
                                                                               value={paginationOption.value}>{paginationOption.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="row mb-5 d-flex align-items-center justify-content-center">
                                                    <span>{`Results ${(this.props.gallery.itemsCountPerPage * this.props.gallery.currentPageValue) - this.props.gallery.itemsCountPerPage + 1} - ${(this.props.gallery.itemsCountPerPage * this.props.gallery.currentPageValue) - this.props.gallery.itemsCountPerPage + this.props.gallery.list.length} of ${this.props.gallery.totalItems}`}</span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </ScrollLock>
                                <SearchResultInfoPanel
                                    isOpen={this.props.gallery.isSidePanelOpen}
                                    closeSidePanel={this.props.doCloseAndUnsetImageForGallery}
                                    image={this.props.gallery.imageForSidePanel}
                                    notesValue={this.props.reportsList.notes}
                                    setNotes={this.props.setNotes}
                                    saveNotes={this.props.saveNotes}
                                />
                                <AddToListModal
                                    visible={this.props.gallerySub.addToListModalToggle}
                                    setVisible={this.props.setAddToListModalToggle}
                                    userLists={this.props.userLists}
                                    setModalStatus={this.props.setAddToListModalToggle}
                                    onListSelect={this.props.setSelectedList}
                                    selectedList={this.props.userLists.selectedList}
                                    addToList={this.props.addManyToList}
                                    items={this.state.selectedImages}
                                    doCreateList={this.props.doCreateList}
                                    fetchUserListData={this.props.fetchUserListData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        reportsList: state.reportsList,
        gallery: state.gallery,
        labels: state.labels,
        menu: state.reportsListMenu,
        projectList: state.projectList,
        userLists: state.userLists,
        gallerySub: state.gallerySub
    }
};

const mapActionToProps = (dispatch) => {
    return {
        setFilterPanelToggle: (status) => {
            dispatch(setFilterPanelToggle(status))
        },
        setAddToListModalToggle: (status) => {
            dispatch(setAddToListModalToggle(status))
        },
        fetchGallerySearchResults: (filters, selectedImages) => {
            dispatch(fetchGallerySearchResults(filters, selectedImages))
        },
        setItemsCountPerPage: (perPage) => {
            dispatch(setItemsCountPerPage(perPage))
        },
        setCurrentPage: (page) => {
            dispatch(setCurrentPage(page))
        },
        fetchLabels: () => {
            dispatch(fetchLabels())
        },
        addLabelToSearchResult: (label, searchResultId) => {
            dispatch(addLabelToSearchResult(label, searchResultId))
        },
        removeLabelFromSearchResult: (label, searchResultId) => {
            dispatch(removeLabelFromSearchResult(label, searchResultId))
        },
        addManyLabelsToManySearchResults: (labels, searchResultIds) => {
            dispatch(addManyLabelsToManySearchResults(labels, searchResultIds))
        },
        fetchUserListData: () => {
            dispatch(fetchUserListData())
        },
        doCreateList: (listName, listDesc) => {
            dispatch(doCreateList(listName, listDesc))
        },
        setSelectedList: (selectedList) => {
            dispatch(setSelectedList(selectedList))
        },
        // fetchSingleViewImageData: (searchResultId) => {
        //     dispatch(fetchSingleViewImageData(searchResultId))
        // },
        // fetchUserListData: () => {
        //     dispatch(fetchUserListData())
        // },
        addManyToList: (searchResults, listId) => {
            dispatch(addManyToList(searchResults, listId))
        },
        // doPrint: (isUserDefinedList, searchResultIds, listName, listDesc) => {
        //     dispatch(doPrint(isUserDefinedList, searchResultIds, listName, listDesc))
        // }

        // Sidepanel
        doOpenAndSetImageForGallery: (selectedImage) => {
            dispatch(doOpenAndSetImageForGallery(selectedImage))
        },
        doCloseAndUnsetImageForGallery: () => {
            dispatch(doCloseAndUnsetImageForGallery())
        },
        setNotes: (notes) => {
            dispatch(setNotes(notes))
        },
        saveNotes: (searchResultId, notes) => {
            dispatch(saveNotes(searchResultId, notes))
        }
    }
};

export default connect(mapStateToProps, mapActionToProps)(GalleryList)
