import AuthAgent from '../libs/AuthAgent';
import {toast} from 'react-toastify';
import axios from "axios";

export const SET_BOOKMARK = 'bookmarks:set';

export const setBookMark = (status) => {
    return {
        type: SET_BOOKMARK,
        payload: {
            status
        }
    }
}

export const doBookmark = (searchResultsId, bookmarkStatus) => {
    return dispatch => {

        let payload = {
            id: searchResultsId,
            bookmark: bookmarkStatus
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/bookmark`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            dispatch(setBookMark(response.data.bookmark));
            let endMessage = response.data.bookmark ? ' bookmarked' : ' removed from bookmarks'
            toast('Search result' + endMessage)
        }).catch((error) => {
            toast('Something went wrong')
        })
    }
}