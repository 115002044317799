import React, {useState} from "react";
import {Dropdown, DropdownToggle} from "reactstrap";
import DropdownMenuAction from "./DropdownMenuAction";

const ActionDropdown = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false)

    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} direction={'down'}>
            <DropdownToggle color="primary" size="sm" caret>{props.label}</DropdownToggle>
            {dropdownOpen &&
                <DropdownMenuAction
                    row={props.row}
                />
            }
        </Dropdown>
    )

}

export default ActionDropdown;