import axios from "axios";

export default function cleanupOnLogout() {

    axios(process.env.REACT_APP_API_BASE_URL + "/logout", {
        method: 'GET',
        withCredentials: true,
    }).then(response => {
    }).catch((error) => {
        console.log(error)
    })

    localStorage.removeItem('project')
    localStorage.removeItem('user_handle')
}
