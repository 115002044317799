export function getStatusFilterOptions() {
    return [
        {value: null, label: 'All'},
        // {value: 1, label: "Shortlisted"},
        // {value: 6, label: "Auto Shortlisted"},
        // {value: 2, label: "Rejected"},
        // {value: 3, label: "Auto Rejected"},
        // {value: 4, label: "Confirmed"},
        // {value: 8, label: "Staging"},
        {value: 7, label: "Current month"},
        {value: 5, label: "Enforced"},
        {value: 9, label: "Previous months"}
    ]
}