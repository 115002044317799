import React, { Component } from 'react';
import Sidebar from "../parts/layout/Sidebar";
import Topbar from "../parts/layout/Topbar";
import {doFetchSummary, doFetchSummaryList, setSummaryOption} from "../../actions/summary";
import {connect} from "react-redux";
import SummaryView from "../parts/summary/SummaryView";
import DashboardSub from "../parts/layout/subheader/DashboardSub";

class Summary extends Component {

    componentDidMount() {
        this.props.doFetchSummaryList()
    }

    handleOnSummaryChange = (option) => {
        this.props.setSummaryOption(option)
        this.props.doFetchSummary(option.value)
    }

    render() {
        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar />
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="summary-content">
                          <Topbar pageTitle={this.props.pageTitle} />
                          <DashboardSub
                            options={this.props.summary.summaryList.map(item => {
                                return {value: item.id, label: item.title}
                            })}
                            handleSelect={this.handleOnSummaryChange}
                            selectedValue={this.props.summary.selectedSummaryOption}
                          />
                          <div className="container-fluid">
                              <div className="row">
                                  {/*<div className="col-4">*/}
                                  {/*    <Select*/}
                                  {/*      styles={customStyles}*/}
                                  {/*      options={this.handleSelectOptions()}*/}
                                  {/*      onChange={option => this.handleOnSummaryChange(option)}*/}
                                  {/*      value={this.props.summary.selectedSummaryOption}*/}
                                  {/*    />*/}
                                  {/*</div>*/}
                                  <div className="col-12">
                                      {this.props.summary.fetching ?
                                        <div
                                          className="col-12 h-100 d-flex align-items-center justify-content-center text-primary">
                                            <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                                        </div>
                                        : (
                                          <div className="row">
                                              {this.props.summary.summary &&
                                              <SummaryView
                                                  summary={this.props.summary.summary}
                                              />
                                              }
                                          </div>
                                        )
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        summary: state.summary
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        doFetchSummaryList: () => {
            dispatch(doFetchSummaryList())
        },
        doFetchSummary: (summaryId) => {
            dispatch(doFetchSummary(summaryId))
        },
        setSummaryOption: (option) => {
            dispatch(setSummaryOption(option))
        }
    }
};

export default connect(mapStateToProps, mapActionsToProps)(Summary);
