import React, {Component, Fragment} from 'react';
import TemplateSection from "./TemplateSection"
import determineProjectTemplate from '../../../utils/projectTemplates/determineProjectTemplate';
import AddToListModal from '../../parts/reportsList/AddToListModal';
import {Link} from "react-router-dom";
import Label from "../labels/Label";

class SingleSearchResultCardViewer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            listValue: ''
        }
    }

    // handleBookmarkColorCue = () => {
    //     return this.props.imageData.bookmarked ? 'far fa-bookmark text-primary svs-header-icon' : ' far fa-bookmark text-gray-400 svs-header-icon'
    // }
    //
    // handleReviewedColorCue = () => {
    //     return this.props.imageData.reviewed_by_client ? 'far fa-eye text-primary svs-header-icon' : ' far fa-eye text-gray-400 svs-header-icon'
    // }
    //
    // handleNotesVisualCue() {
    //     return this.props.imageData.notes ? 'fas fa-sticky-note text-primary svs-header-icon' : ' fas fa-sticky-note text-primary text-gray-400 svs-header-icon'
    // }

    setModalStatus = (status) => {
        this.setState({visible: status})
    }

    handleRemoveLabel = (label) => {
        this.props.removeLabelFromSearchResult(label, this.props.imageData.id)
    }

    render() {
        let template = determineProjectTemplate(this.props.imageData)
        return (
          <Fragment>
              {this.props.fetching ?
                <div className="row">
                    <div className="col d-flex justify-content-center text-primary">
                        <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                    </div>
                </div>
                :
                <div className="card mb-4 svs-card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col labels-list">
                                {this.props.imageData.labels.map(label => {
                                    return (
                                        <Label key={label.id} label={label} handleRemove={this.handleRemoveLabel}/>
                                    )
                                })}
                            </div>
                            {/*<div className="col">*/}
                            {/*    /!*<i title="Toggle bookmark" onClick={() => this.props.doBookmark(this.props.imageData.id, !this.props.imageData.bookmarked)} className={this.handleBookmarkColorCue()}></i>*!/*/}
                            {/*    /!*<i title="Toggle reviewed" onClick={() => this.props.doReview(this.props.imageData.id, !this.props.imageData.reviewed_by_client)} className={this.handleReviewedColorCue()}></i>*!/*/}
                            {/*    /!*<i title="Has note" className={this.handleNotesVisualCue()}></i>*!/*/}
                            {/*    /!*<span className="pipe text-primary">|</span>*!/*/}
                            {/*    <i title="Add to list" onClick={() => this.setModalStatus(true)} className="fas fa-list text-primary svs-header-icon"></i>*/}
                            {/*    {this.props.hasRemoveFromList &&*/}
                            {/*        <i title="Remove from list" onClick={(e) => this.props.handleRemoveFromList(this.props.imageData.id, this.props.currentList)} className="fas fa-times text-primary svs-header-icon"></i>*/}
                            {/*    }*/}
                            {/*    <div className="dropdown d-inline-block">*/}
                            {/*                <span className="nav-link" href="#" id="userDropdown" data-toggle="dropdown" role="button"*/}
                            {/*                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                      <i className="fas fa-tag text-primary"></i>*/}
                            {/*                </span>*/}
                            {/*        <div className="dropdown-menu">*/}
                            {/*                      <span className="dropdown-item small" href="#" role="button">*/}
                            {/*                          <Link to="/labels" className="px-3 py-1 mx-1 d-inline-block">Create new label</Link>*/}
                            {/*                      </span>*/}
                            {/*            {this.props.existingLabels.map(label => {*/}
                            {/*                return (*/}
                            {/*                    <span key={label.id} className="dropdown-item small" href="#" role="button" onClick={(e) => this.handleAddLabel(label)}>*/}
                            {/*                              <span className="px-3 py-1 mx-1 d-inline-block" style={{backgroundColor: `#${label.label_color}`, color: `#${label.text_color}`}}>{label.name}</span>*/}
                            {/*                          </span>*/}
                            {/*                )*/}
                            {/*            })}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col d-flex align-items-center justify-content-end m-0 text-white">
                                <span className="mb-0 ml-3">{this.props.currentIndex + 1} / {this.props.listLength}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                            <div className="row">

                            </div>

                        <div className="row">
                            <div className="col-4 left-svs-col">
                                {/*<h6 className="m-0 font-weight-bold text-primary">ID: {this.props.imageData.id}</h6>*/}
                                {template.sections.map((templateSection) => {
                                    if (templateSection.name === "Image of found dupe") {
                                        return (
                                          <div className="col-12 summary-col" key={templateSection.name}>
                                              {templateSection.name === "Image of found dupe" &&
                                              <TemplateSection
                                                sectionTitle={templateSection.name}
                                                fields={templateSection.fields}
                                                imageData={this.props.imageData}
                                                forPanel={false}
                                                  // fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                                setNotes={this.props.setNotes}
                                                saveNotes={this.props.saveNotes}
                                                notesValue={this.props.notesValue}
                                              />
                                              }
                                          </div>
                                        )
                                    }
                                return null
                                })
                                }
                            </div>
                            <div className="col-8 right-svs-col">
                                {template.sections.map((templateSection) => {
                                    if (templateSection.name !== "Image of found dupe") {
                                        return (
                                          <div className="col-12 sections-col" key={templateSection.name}>
                                              <TemplateSection
                                                sectionTitle={templateSection.name}
                                                fields={templateSection.fields}
                                                forPanel={false}
                                                imageData={this.props.imageData}
                                                // fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                                setNotes={this.props.setNotes}
                                                saveNotes={this.props.saveNotes}
                                                notesValue={this.props.notesValue}
                                              />
                                          </div>
                                        )
                                    }
                                return null
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
              }
          </Fragment>
        )
    }
}

export default SingleSearchResultCardViewer
