import React from 'react';
import {connect} from 'react-redux';
import Sidebar from "../parts/layout/Sidebar";
import Topbar from "../parts/layout/Topbar";
import DataTable from "react-data-table-component";
import Header from "../parts/lists/Header";
import ListLabel from "../parts/labels/ListLabel";
import memoize from "memoize-one";
import {doCreateLabel, doDeleteLabel, doUpdateLabel, fetchLabels} from "../../actions/labels";
import moment from "moment";
import Actions from "../parts/labels/Actions";
import CreateLabelModal from "../parts/labels/CreateLabelModal";
import DeleteLabelModal from "../parts/labels/DeleteLabelModal";
import UpdateLabelModal from "../parts/labels/UpdateLabelModal";
import noData from "../parts/labels/NoData";

const columns = memoize((setDeleteModalStatus, setUpdateModalStatus, setSelectedRow) => [
    {
        name: 'Tags',
        selector: 'name',
        cell: row => <ListLabel row={row}/>,
        maxWidth: '300px',
    },
    {
        name: 'Description',
        selector: 'description',
        maxWidth: '300px',
    },
    // {
    //     name: 'Description',
    //     selector: 'description',
    //     maxWidth: '300px',
    // },
    // {
    //     name: 'Items',
    //     selector: 'item_count',
    //     maxWidth: '300px',
    // },
    {
        name: 'Created',
        // selector: 'created',
        // maxWidth: '300px',
        cell: row => moment(row.created).format('DD/MM/YYYY')
    },
    {
        name: 'Actions',
        // selector: 'created',
        maxWidth: '150px',
        cell: row => <Actions row={row} setSelectedRow={setSelectedRow} setModalStatus={setDeleteModalStatus} setUpdateModalStatus={setUpdateModalStatus} />,
        center: true
    }
]);

class Labels extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            filteredText: '',
            createModalVisible: false,
            deleteModalVisible: false,
            updateModalVisible: false,
            selectedRow: null
        }
    }

    componentDidMount() {
        this.props.fetchLabels();
    }

    setCreateModalStatus = status => {
        this.setState({
            createModalVisible: status
        })
    }

    setDeleteModalStatus = status => {
        this.setState({
            deleteModalVisible: status
        })
    }

    setUpdateModalStatus = status => {
        this.setState({
            updateModalVisible: status
        })
    }

    setSelectedRow = row => {
        this.setState({
            selectedRow: row
        })
    }

    render() {
        const filteredItems = this.props.labels.list.filter(item => item.name && item.name.includes(this.state.filteredText));

        return (
            <div id="page-top">
                <div id="wrapper">
                    <Sidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs ">
                            <Topbar pageTitle={this.props.pageTitle} />
                            <div className="container-fluid no-subheader">
                                <div className="user-lists-table">
                                    <DataTable
                                        columns={columns(this.setDeleteModalStatus, this.setUpdateModalStatus, this.setSelectedRow)}
                                        data={filteredItems}
                                        keyField={'id'}
                                        pagination
                                        paginationPerPage={30}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 75, 100]}
                                        defaultSortField={'created'}
                                        defaultSortAsc={false}
                                        noDataComponent={noData(this.setCreateModalStatus)}
                                        noHeader
                                        subHeader
                                        subHeaderComponent={
                                            <Header
                                                buttonTitle="Create new tag"
                                                value={this.state.filteredText}
                                                handleOnFilterTextChange={(filteredText) => this.setState({filteredText})}
                                                setModalStatus={this.setCreateModalStatus}
                                            />}
                                    />
                                </div>
                            </div>
                            <CreateLabelModal
                                visible={this.state.createModalVisible}
                                setModalStatus={this.setCreateModalStatus}
                                doCreateLabel={this.props.doCreateLabel}
                            />
                            <DeleteLabelModal
                                visible={this.state.deleteModalVisible}
                                setModalStatus={this.setDeleteModalStatus}
                                doDeleteLabel={this.props.doDeleteLabel}
                                label={this.state.selectedRow}
                            />
                            <UpdateLabelModal
                                visible={this.state.updateModalVisible}
                                setModalStatus={this.setUpdateModalStatus}
                                doUpdateLabel={this.props.doUpdateLabel}
                                label={this.state.selectedRow}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        labels: state.labels
    }
}

const mapActionToProps = (dispatch) => {
    return {
        fetchLabels: () => {
            dispatch(fetchLabels())
        },
        doCreateLabel: (labelData) => {
            dispatch(doCreateLabel(labelData))
        },
        doDeleteLabel: (label) => {
            dispatch(doDeleteLabel(label))
        },
        doUpdateLabel: (label, labelData) => {
            dispatch(doUpdateLabel(label, labelData))
        }
    }
}

export default connect(mapStateToProps, mapActionToProps)(Labels)