import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {formatNumberAsIntl} from "../../../../utils/various/format";

import {
    XYPlot,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    HorizontalBarSeries,
    LabelSeries,
} from 'react-vis';
import {initialState} from "../../../../reducers/reportsListMenuReducer";
import getPropertyTypeFilterOptions from "../../../../utils/dataSelection/propertyTypeFilterOptions";
import {ROUTES} from "../../../../routes/frontend";
import {getStatusFilterOptions} from "../../../../utils/dataSelection/statusFilterOptions";

class BarChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            useCanvas: false,
        };
    }

    parseColorRange = () => {
        if (this.props.data.length !== 3) {
            return ['#496812', '#567A16', '#638B1A', '#719F1F', '#7EB023', '#86bc25', '#A0CC4A', '#B4D967', '#C8E684', '#DCF2A1']
        } else {
            return ["#496812", "#86bc25", "#DCF2A1"]
        }
    }

    parseColorDomain = () => {
        if (this.props.data.length !== 3) {
            return [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
        } else {
            return this.colorDomain = [2, 1, 0]
        }
    }

    onBarValueClick = (value) => {
        this.props.resetFilters(initialState)
        const { y } = value

        let option = []

        this.props.setStartDate(new Date(this.props.summary.from))
        this.props.setEndDate(new Date(this.props.summary.to))

        if (y === 'Web') {
            option = getPropertyTypeFilterOptions().find(option => option.label === y)
            this.props.setPropertyType([option])
            this.props.router.push(ROUTES.galleryList.path)

            return
        }

        this.props.setRemoteStatus(getStatusFilterOptions()[0])
        this.props.setHost(y)
        this.props.router.push(ROUTES.galleryList.path)
    }

    render() {

        let data = [...this.props.data];

        if (this.props.showWeb === false) {
            data = data.filter(item => item.platform !== 'Web')
        }

        return (
          <div className="card platform-split">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold">{this.props.cardTitle}</h6>
              </div>
              <div className="card-body d-block">
                  {/*<h4>{this.props.title}</h4>*/}
                  {/*<p>{this.props.subTitle}</p>*/}
                  <hr/>
                  <div className="auto-resizer d-block" style={{height: '100%', width: '100%'}}>
                      <AutoSizer>
                          {({ height, width }) => (
                            <XYPlot
                              margin={{bottom: 40, left: 100, right: 100}}
                              yType="ordinal" width={width} height={300}
                              colorType="linear"
                              colorRange={this.parseColorRange()}
                              colorDomain={this.parseColorDomain()}
                            >
                                {/*<VerticalGridLines/>*/}
                                {/*<HorizontalGridLines/>*/}
                                <YAxis/>
                                  <HorizontalBarSeries
                                      className="bar-property-type-split"
                                      onValueClick={value => this.onBarValueClick(value)}
                                      barWidth={0.5} data={data.reverse().map((i, index) => {return {y: i.platform, x: i.count, label: i.count, color: [index]}
                                  })}/>

                                <LabelSeries
                                  labelAnchorY="middle"
                                  yOffset={200}
                                  labelAnchorX='middle-alignment'
                                  data={data.reverse().map(i => {
                                      return {
                                          y: i.platform,
                                          x: i.count,
                                          label: `${formatNumberAsIntl(i.count)}`, // Needs to be a string
                                          style: {fontSize: '14px'},
                                          xOffset: 5,
                                          yOffset: 2,
                                      }
                                  })}
                                />
                            </XYPlot>
                          )}
                      </AutoSizer>
                  </div>
              </div>
          </div>
        );
    }
}

export default BarChart;