import React, {Component} from 'react';
import Topbar from "../parts/layout/Topbar";
import Toggle from 'react-toggle';
import AuthAgent from "../../libs/AuthAgent";
import {toast} from "react-toastify";
import {Cookies} from "react-cookie-consent";
import {CookieInfo} from "../parts/cookie/CookieInfo";
import {COOKIE_DURATION, COOKIE_NAME} from "../../utils/cookies/cookieConstants";
import axios from "axios";
import {appInsights} from "../../utils/appInsights/getInstance";

class ManageCookies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: typeof Cookies.get(COOKIE_NAME) === "undefined" ? false : this.convertCookieValueToBoolean(),
        }
    }

    convertCookieValueToBoolean = () => {
        return Cookies.get(COOKIE_NAME) !== 'false';
    }

    onToggleChange = (e) => {
        if (e.target.checked === false) {
            axios({
                url: process.env.REACT_APP_API_BASE_URL + '/cookies/reject',
                method: 'GET',
                withCredentials: true,
                headers: AuthAgent.getHeaders()
            }).then(() => {
                Cookies.set(COOKIE_NAME, e.target.checked, {expires: COOKIE_DURATION, domain: process.env.HOST})
                window.location.reload()
            }).catch(e => {
                console.log(e)
            })
        } else {
            axios({
                url: process.env.REACT_APP_API_BASE_URL + '/cookies/accept',
                method: 'GET',
                withCredentials: true,
                headers: AuthAgent.getHeaders()
            }).then(() => {
                Cookies.set(COOKIE_NAME, e.target.checked, {expires: COOKIE_DURATION, domain: process.env.HOST})
                // toast('Cookie preferences updated!');
                window.location.reload()
            }).catch(e => {
                console.log(e)
            })
        }

        this.setState({
            checked: !!e.target.checked
        })
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="manage-cookies">
                <Topbar/>
                <div className="cookie-nav">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <div className="back-link">
                                    <a onClick={this.handleBack} href="#">
                                        <i className="fas fa-arrow-left mr-2"></i>
                                        BACK
                                    </a>
                                </div>
                                <p className="text-gray-200 mb-0">YOU ARE COMPLETING: <span className="font-weight-bold">COOKIE PREFERENCES</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cookie-intro text-gray-200">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-6 text-center">
                                <p className="mb-3 font-weight-bold">OUR COOKIE NOTICE</p>
                                <h2 className="h2 mb-3">Customise your cookie preferences</h2>
                                <p className="mb-0">Cookies are files saved to your phone, tablet or computer when you visit a website. Think of them as a digital footprint or cookie trail.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="my-5">Manage cookies</h1>
                            <div className="mb-5">
                                <h4>What are cookies?</h4>
                                <p>Cookies are files saved to your phone, tablet or computer when you visit a website. They compose your digital footprint, or cookie trail, ard the types of cookies you share with us is up to you. </p>
                            </div>
                            <div className="mb-5">
                                <h4>How do we use cookies?</h4>
                                <p>Deloitte uses strictly necessary cookies and similar technologies to operate this website. In addition to strictly necessary cookies, Deloitte uses optional cookies to enhance and personalise your experience, conduct analytics such as count visits and traffic sources, interact with third parties. Please see and amend your cookie preferences using the toggle(s) below.</p>
                            </div>
                            <div className="accordion-container mb-5">
                                <div className="accordion">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4>Our cookies</h4>
                                        <i data-toggle="collapse" data-target="#cookie-accordion" className="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                                    <div id="cookie-accordion" className="collapse">
                                        <CookieInfo
                                            name="client_Browser"
                                            host="dupekiller.ai"
                                            duration="90 days"
                                            type="First party"
                                            category="Analytics and performance cookies"
                                            description="This cookie allows us to track analytics by counting visits from different browsers in order to measure and improve the performance of our website."
                                        />
                                        <CookieInfo
                                            name="client_City"
                                            host="dupekiller.ai"
                                            duration="90 days"
                                            type="First party"
                                            category="Analytics and performance cookies"
                                            description="This cookie allows us to track the origin of our visitors for analytical studies."
                                        />
                                        <CookieInfo
                                            name="client_CountryOrRegion"
                                            host="dupekiller.ai"
                                            duration="90 days"
                                            type="First party"
                                            category="Analytics and performance cookies"
                                            description="This cookie allows us to track the origin of our visitors for analytical studies."
                                        />
                                        <CookieInfo
                                            name="client_StateOrProvince"
                                            host="dupekiller.ai"
                                            duration="90 days"
                                            type="First party"
                                            category="Analytics and performance cookies"
                                            description="This cookie allow us to analytically track the type of device through which our system is accessed to improve the performance of our website."
                                        />
                                        <CookieInfo
                                            name="client_Type"
                                            host="dupekiller.ai"
                                            duration="90 days"
                                            type="First party"
                                            category="Analytics and performance cookies"
                                            description="This cookie allows us to track the origin of our visitors for analytical studies."
                                        />
                                        <CookieInfo
                                            name="custom_Dimensions"
                                            host="dupekiller.ai"
                                            duration="90 days"
                                            type="First party"
                                            category="Analytics and performance cookies"
                                            description="Cookie used to manage the internal data and infrastructure of our system."
                                        />
                                        <CookieInfo
                                            name="custom_Measurements"
                                            host="dupekiller.ai"
                                            duration="90 days"
                                            type="First party"
                                            category="Analytics and performance cookies"
                                            description="This cookie allows us to know the performance of our website in different platforms."
                                        />
                                        <CookieInfo
                                            name="user_id"
                                            host="dupekiller.ai"
                                            duration="Session"
                                            type="First party"
                                            category="Strictly necessary cookies"
                                            description="An unique ID used by the system to perform basic functions such as visitor or account identification across domains & ID synchronization."
                                        />
                                    </div>
                            </div>
                            <div className="mb-5">
                                <h4>The two types of cookies we use</h4>
                                <p>Please find our list of cookies below, and choose which ones you're happy with:</p>
                            </div>
                            <div className="toggle-and-text-container mb-4">
                                <Toggle
                                    onChange={e => this.onToggleChange(e)}
                                    checked={this.state.checked}
                                    icons={false}
                                />
                                <div className="text-container">
                                    <h5 className="d-inline-block">Analytics and performance cookies</h5>
                                    <p className="mt-3">These cookies allow us to track analytics by counting visits and traffic sources so we can measure and improve the performance of our website. They may be set by us or by third party providers to help us to know which pages are the most and least popular and see how visitors move around the website.
                                    </p>
                                </div>
                            </div>

                            <span className="always-active-badge mr-3">Always active</span><h5 className="d-inline ml-3">Strictly necessary cookies</h5>
                            <p className="mt-3">These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences or filling in forms.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManageCookies;