import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../utils/sso/authConfig";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = (props) => {

    const { instance } = useMsal();

    const handleLogin = async (e) => {

        e.preventDefault()

        try {
            const response =  await instance.loginPopup(loginRequest)
            props.onLoginSubmit(response.idToken)
        } catch (e) {
            console.log(e)
        }
    }

    return (
          <button onClick={(e) => handleLogin(e)} type="button" className="btn btn-success btn-user btn-block">
              Sign in with Deloitte
          </button>
    )
}