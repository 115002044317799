import React, {Component} from "react";
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import DeloitteLogo from './../../assets/images/del_logo_white.png';
import {fetchAuthData, fetchAuthDataSso} from '../../actions/authentication';
import Validator from '../../libs/Validation';
import {SignInButton} from "../parts/login/sso/SsoSignInButton";

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            emailErrors: Validator.getEmailErrorsStructure(),
            passwordErrors: Validator.getPasswordErrorsStructure()
        }
    }

    handleLoginSubmit = e => {
        e.preventDefault()
        let errors = Validator.validateLoginData(this.state.email, this.state.password)

        this.setState({
            emailErrors: errors.email,
            passwordErrors: errors.password
        })

        if (errors.formHasErrors === false) {
            this.props.onLoginSubmit(this.state.email, this.state.password)
        }
    }

    handleEmailOnBlur = e => {

        let errors = Validator.validateLoginEmail(this.state.email)
        this.setState({
            emailErrors: errors.email
        })
    }

    handlePasswordOnBlur = e => {

        let errors = Validator.validateLoginPassword(this.state.password)
        this.setState({
            passwordErrors: errors.password
        })
    }

    handleInputVisualValidationStateForEmail = () => {
        let validationClass = "";

        if (this.state.emailErrors.hasErrors === true) {
            validationClass = "is-invalid"
        } else if (this.state.emailErrors.hasErrors === false) {
            validationClass = "is-valid"
        }

        return validationClass
    }

    handleInputVisualValidationStateForPassword = () => {
        let validationClass = "";

        if (this.state.passwordErrors.hasErrors === true) {
            validationClass = "is-invalid"
        } else if (this.state.passwordErrors.hasErrors === false) {
            validationClass = "is-valid"
        }

        return validationClass
    }

    render() {
        return (
          <div className="login-page h-100">
              <nav className="navbar navbar-light">
                  <span className="navbar-brand">
                      <img src={DeloitteLogo} width="150" height="auto" alt=""/>
                  </span>
              </nav>
              <div className="container-fluid">
                  <div className="row justify-content-center align-items-center">
                      <div className="col-xl-3 col-lg-3 col-md-3">
                          <div className="card o-hidden p-0 login-card">
                              <div className="card-body overflow-hidden p-0">
                                  <div className="row">
                                      {/*<div className="col-lg-6 bg-secondary d-flex align-items-center">*/}
                                      {/*<img className="img-fluid" src={DkLargeLogo} alt="DK logo" />*/}
                                      {/*</div>*/}
                                      <div className="col p-5 p-md-5 p-lg-5">
                                          <h1 className="h3 mb-4">Dupe Killer login</h1>
                                          {this.props.authentication.authError &&
                                          <div
                                            className="alert alert-danger bg-gradient-danger text-gray-100">{this.props.authentication.errorMessage}</div>
                                          }
                                          <form formMethod="POST" onSubmit={this.onLoginSubmit} className="user">
                                              {process.env.REACT_APP_ENV !== 'production' &&
                                              <>
                                                  <div className="form-group">
                                                      <input onChange={e => this.setState({email: e.target.value})}
                                                             onBlur={e => this.handleEmailOnBlur(e)}
                                                             value={this.state.email} type="email"
                                                             className={`form-control form-control-user ${this.handleInputVisualValidationStateForEmail()}`}
                                                             id="exampleInputEmail" aria-describedby="emailHelp"
                                                             placeholder="Enter Email Address..."/>
                                                      {this.state.emailErrors.errorMessages.length > 0 &&
                                                      <div className="invalid-feedback">
                                                          {this.state.emailErrors.errorMessages.map(message => message)}
                                                      </div>
                                                      }
                                                  </div>

                                                  <div className="form-group">
                                                      <input onChange={e => this.setState({password: e.target.value})}
                                                             onBlur={e => this.handlePasswordOnBlur(e)}
                                                             value={this.state.password} type="password"
                                                             className={`form-control form-control-user ${this.handleInputVisualValidationStateForPassword()}`}
                                                             id="exampleInputPassword" placeholder="Password"/>
                                                      {this.state.passwordErrors.errorMessages.length > 0 &&
                                                      <div className="invalid-feedback">
                                                          {this.state.passwordErrors.errorMessages.map(message => message)}
                                                      </div>
                                                      }
                                                  </div>
                                                  <button onClick={e => this.handleLoginSubmit(e)} type="submit"
                                                          className="btn btn-secondary btn-user w-100 mb-3">
                                                      {this.props.authentication.fetching ?
                                                        (
                                                          <div className="spinner-border text-white" role="status">
                                                              <span className="sr-only">Loading...</span>
                                                          </div>
                                                        ) : (
                                                          <span>Login</span>
                                                        )
                                                      }
                                                  </button>
                                              </>
                                              }
                                              <SignInButton onLoginSubmit={this.props.onLoginSubmitSso} />
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    }
};

const mapActionsToProps = (dispatch, ownProps) => {
    return {
        onLoginSubmit: (email, password, history) => {
            dispatch(fetchAuthData(email, password, ownProps.history))
        },
        onLoginSubmitSso: (idToken) => {
            dispatch(fetchAuthDataSso(idToken, ownProps.history))
        }
    }
};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withRouter
)(Login);