import AuthAgent from "../libs/AuthAgent";
import axios from "axios";

export const SET_LABELS = "labels:set";
export const LABEL_SEARCH_RESULT = "labels:searchResult";
export const LABEL_MANY_SEARCH_RESULTS = "labels:labelManySearchResultsWithManyLabels"
export const REMOVE_LABEL_SEARCH_RESULT = "labels:removeFromSearchResult";
export const DELETE_LABEL = "labels:delete"
export const CREATE_LABEL = "labels:create"

export const setLabels = (labels) => {
    return {
        type: SET_LABELS,
        payload: {
            labels
        }
    }
}

export const setSearchResultsLabel = (label, searchResultId) => {
    return {
        type: LABEL_SEARCH_RESULT,
        payload: {
            label,
            searchResultId
        }
    }
}

export const setManyLabelsForManySearchResults = (label, searchResultIds) => {
    return {
        type: LABEL_MANY_SEARCH_RESULTS,
        payload: {
            label,
            searchResultIds
        }
    }
}

export const unsetSearchResultsLabel = (label, searchResultId) => {
    return {
        type: REMOVE_LABEL_SEARCH_RESULT,
        payload: {
            label,
            searchResultId
        }
    }
}

export const deleteLabel = (label) => {
    return {
        type: DELETE_LABEL,
        payload: {
            label
        }
    }
}

export const createLabel = (labelData) => {
    return {
        type: CREATE_LABEL,
        payload: {
            labelData
        }
    }
}

export const fetchLabels = () => {
    return dispatch => {

        let projectId = JSON.parse(localStorage.getItem('project')).id

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/labels/project/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(setLabels(response.data))
        }).catch(error => {
            console.log(error.message)
        })
    }
}

export const addLabelToSearchResult = (label, searchResultId) => {
    return dispatch => {

        let payload = {
            label_id: label.id,
            search_results: [searchResultId]
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/labels/${label.id}/search-results`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            dispatch(setSearchResultsLabel(response.data, searchResultId))
        }).catch(error => {
            console.log(error.message)
        })
    }
}

export const addManyLabelsToManySearchResults = (labels, searchResultIds) => {
    return dispatch => {

        labels.forEach(label => {
            console.log(label)
            let payload = {
                label_id: label.id,
                search_results: [...searchResultIds]
            }

            axios({
                url: process.env.REACT_APP_API_BASE_URL + `/labels/${label.id}/search-results`,
                method: 'POST',
                withCredentials: true,
                headers: AuthAgent.getHeaders(),
                data: payload
            }).then(response => {
                dispatch(setManyLabelsForManySearchResults(response.data, [...searchResultIds]))
            }).catch(error => {
                console.log(error.message)
            })
        })
    }
}

export const removeLabelFromSearchResult = (label, searchResultId) => {
    return dispatch => {

        let payload = {
            label_id: label.id,
            search_results: [searchResultId] // Backend expects an array of ids
        }

        console.log(payload)

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/labels/${label.id}/search-results`,
            method: 'DELETE',
            withCredentials: 'include',
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(response => {
            dispatch(unsetSearchResultsLabel(response.data, searchResultId))
        }).catch(error => {
            console.log(error)
        })
    }
}

export const doDeleteLabel = (label) => {
    return dispatch => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/labels/${label.id}`,
            method: 'DELETE',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
        }).then(response => {
            dispatch(fetchLabels())
            dispatch(deleteLabel(response.data))
        }).catch(error => {
            console.log(error.message)
        })
    }
}

export const doCreateLabel = (labelData) => {

    return dispatch => {

        let projectId = JSON.parse(localStorage.getItem('project')).id

        let payload = {
            name: labelData.name,
            description: labelData.description,
            label_color: labelData.labelColor.replace('#', '')
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/labels/project/${projectId}`,
            method: 'POST',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(() => {
            dispatch(fetchLabels())
        }).catch(error => {
            console.log(error.message)
        })
    }
}

export const doUpdateLabel = (label, labelData) => {
    return dispatch => {

        let payload = {
            name: labelData.name,
            description: labelData.description,
            label_color: labelData.labelColor.replace('#', '')
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/labels/${label.id}`,
            method: 'PUT',
            withCredentials: true,
            headers: AuthAgent.getHeaders(),
            data: payload
        }).then(() => {
            dispatch(fetchLabels())
        }).catch(error => {
            console.log(error.message)
        })
    }
}

