// import * as bookmarkActions from '../actions/bookmarks';

const initialState = {
    ids: [],
    imageData: null,
    fetching: false,
    fetchingIds: false,
    currentIdIndex: null,
    nextIdIndex: null,
    previousIdIndex: null,
    currentId: null,
    nextId: null,
    prevId: null,
};

export default function bookmarksReducer(state = initialState, action) {
    switch (action.type) {

        default:
            return state
    }
}
